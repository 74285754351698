import React, { useState, useEffect } from 'react';
import { PubicStyle } from './styles';
import { useParams, useNavigate } from 'react-router-dom';

import { db } from '../../lib/firebase';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { PubicNews } from '../../@types/types';
import Typewriter from 'typewriter-effect';

import { createComponent } from '@lit/react';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

const News = () => {
  const [newId, setNewId] = useState<PubicNews[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const checkTheNews = async (id: string) => {
      const userRef = collection(db, 'articles');
      const checkArticle = query(userRef, where('id', '==', id));
      const querySnapshot = await getDocs(checkArticle);

      querySnapshot.forEach((doc) => {
        const art: PubicNews[] = [];
        art.push(doc.data() as PubicNews);
        setNewId(art);
      });
    };

    checkTheNews(id!);

    return () => {
      setNewId([]);
    };
  }, [id]);

  const firstItem = newId[0];

  return (
    <PubicStyle>
      <div className="public-news">
        #{id}
        {newId.length !== 0 ? (
          <>
            <h1 className="public-news__title">
              <Typewriter
                onInit={(typewriter) => {
                  typewriter.typeString(firstItem.title).start();
                }}
                options={{ delay: 30 }}
              />
            </h1>
            <img
              src={`https://firebasestorage.googleapis.com/v0/b/whitehat4u-d7536.appspot.com/o/articles%2F${firstItem.cover}?alt=media&token=de7011a0-bc3f-46ea-adee-daf5ba9a6f42`}
              alt=""
              className="public-news__image"
            />
            <div
              key={`some-${id}`}
              dangerouslySetInnerHTML={{ __html: `${firstItem.content}` }}
              style={{
                textAlign: 'justify',
                marginTop: '2em',
                fontSize: '16px',
                color: '#282d2e',
                fontFamily: "'Abel', sans-serif",
              }}></div>

            <div style={{ marginTop: '40px' }} onClick={() => navigate(-1)}>
              <MyElementComponent
                labelTemplate="Back"
                colors="wh4u"
                type="button"></MyElementComponent>
            </div>
          </>
        ) : (
          <>Loading</>
        )}
      </div>
      <div className="c-two">Ads Here</div>
    </PubicStyle>
  );
};

export default News;
