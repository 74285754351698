import { useState } from 'react';
import { RegisterStyle } from './registerStyle';
import { useNavigate } from 'react-router-dom';
import { createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getAuth, sendEmailVerification } from '../../lib/firebase';
import { collection, addDoc } from 'firebase/firestore';
import { db } from '../../lib/firebase';
import { doesEmailExist } from '../../services/emailExist';
import { DASHBOARD } from '../../staticContent/routes';

export default function Register() {
  const auth = getAuth();
  const navigate = useNavigate();

  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [terms, setTerms] = useState(false);
  const [data, setData] = useState(false);
  const [phone, setPhone] = useState('');
  const isValidName = name.length > 3 && surname.length > 3;
  const isInvalid =
    email === '' || password === '' || !terms || !data || !isValidName;

  const check =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

  const handleSignup = async (event) => {
    event.preventDefault();

    if (check.test(email) === true) {
      const userEmailExist = await doesEmailExist(email);

      if (userEmailExist === undefined) {
        try {
          const createUser = await createUserWithEmailAndPassword(
            auth,
            email,
            password
          );
          await updateProfile(auth.currentUser, {
            displayName: name,
          });

          await addDoc(collection(db, 'users'), {
            dateCreated: Date.now(),
            email: email.toLowerCase(),
            id: createUser.user.uid,
            name: name.toLowerCase(),
            key: password,
            following: [],
            followers: [],
            likedArticles: [],
            phone: phone || '',
            registeredOn: ['00'],
            surname: surname.toLowerCase(),
          });

          sendEmailVerification(auth.currentUser);

          navigate(DASHBOARD, { replace: true });
        } catch (error) {
          setEmail('');
          setPassword('');
          setName('');
          setSurname('');
          setPhone('');
          setError(error.message);
        }
      } else {
        setError('The current email is already in use');
      }
    } else {
      setEmail('');
      setPassword('');
      setName('');
      setSurname('');
      setPhone('');
      setError('Invalid email');
    }
  };

  const handleTermsChange = (event) => {
    setTerms(event.target.checked);
  };

  const handleDataChange = (event) => {
    setData(event.target.checked);
  };

  return (
    <RegisterStyle>
      <form method="POST" className="form" onSubmit={handleSignup}>
        {error && <p className="mb-2 text-xs text-red">{error}</p>}
        <input
          type="text"
          placeholder="Name"
          autoComplete="off"
          value={name}
          onChange={({ target }) => {
            setName(target.value.toLowerCase());
          }}
        />
        <input
          type="text"
          placeholder="Surname"
          autoComplete="off"
          value={surname}
          onChange={({ target }) => {
            setSurname(target.value.toLowerCase());
          }}
        />
        <input
          type="email"
          placeholder="Email"
          autoComplete="off"
          value={email}
          onChange={({ target }) => {
            setEmail(target.value.toLowerCase());
          }}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
          }}
        />
        <input
          type="phone"
          placeholder="Phone: +48 000 000 000"
          value={phone}
          onChange={({ target }) => {
            setPhone(target.value);
          }}
        />
        <fieldset>
          <legend className="termsSection">Terms of use:</legend>
          <input
            type="checkbox"
            name="terms"
            id="terms"
            onChange={handleTermsChange}
            checked={terms}
            className="terms-checkbox"
          />
          <label for="terms" className="terms-checkbox">
            <a href="/Terms">
              I have read and I accept the terms and conditions
            </a>
          </label>
          <br />
          <input
            type="checkbox"
            name="data"
            id="data"
            onChange={handleDataChange}
            checked={data}
            className="terms-checkbox"
          />
          <label for="data" className="terms-checkbox">
            I consent process my personal data
          </label>
        </fieldset>
        <button
          disabled={isInvalid}
          type="submit"
          className={`button-login ${isInvalid && 'invalid'}`}>
          {isInvalid ? 'Register' : 'REGISTER'}
        </button>
      </form>
    </RegisterStyle>
  );
}
