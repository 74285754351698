import styled from 'styled-components';

export const GeneralButton = styled.div`
  .general-button {
    display: flex;
    justify-content: space-between;
    background: ${(props) => props?.theme.colors.grey};
    min-height: 40px;
    margin-bottom: 20px;

    &__logo {
      background: ${(props) => props?.theme.colors.red};
      width: 50px;
      padding: 10px;
      display: flex;
    }

    &__label {
      width: 100%;
      display: flex;
      align-self: center;
      margin-left: 30px;
    }

    &:hover {
      cursor: pointer;
      background: ${(props) => props?.theme.colors['o-soft']};
      color: ${(props) => props?.theme.colors.white};
      transition: background-color 0.3s, color 0.3s;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
  }
`;
