import React, { useState } from 'react';
import { createComponent } from '@lit/react';

import { StyleCookies } from './StyleCookies';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';
import { useCookies } from 'react-cookie';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

const Cookies = () => {
  const [hide, setHide] = useState(false);
  const [, setCookie] = useCookies(['cookieConsent']);

  const makeCookie = () => {
    setCookie('cookieConsent', true, { path: '/' });
    setHide(true);
  };

  const denyCookies = () => {
    window.location.reload();
  };

  return (
    <StyleCookies>
      <div className={hide ? 'hideCookies' : 'showCookies'}>
        <h2>Cookies policy:</h2>
        <p>
          We use essential cookies and similar tools that are necessary to
          provide our site and services. We use performance cookies to collect
          anonymous statistics so we can understand how customers use our site
          and make improvements. Essential cookies cannot be deactivated, but
          you can click “Deny” if you do not want to use the website.
          <a href="./Cookies"> Read More about them</a>
        </p>
        <span>
          <MyElementComponent
            onClick={denyCookies}
            labelTemplate="Deny"
            colors="wh4u"
            type="button"></MyElementComponent>

          <MyElementComponent
            onClick={makeCookie}
            labelTemplate="Accept"
            colors="wh4u"
            type="button"></MyElementComponent>
        </span>
      </div>
    </StyleCookies>
  );
};

export default Cookies;
