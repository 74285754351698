import styled from 'styled-components';

export const StylePayments = styled.div`
  width: 100%;

  .payment-button {
    button {
      width: 100%;
      height: 30px;
      background-color: ${(props) => props?.theme.colors.black};
      color: white;
      border: none;
      font-family: ${(props) => props?.theme.fonts.secondary};
      font-size: 1.1rem;

      &:hover {
        cursor: pointer;
        background-color: ${(props) => props?.theme.colors.red};
      }
    }
  }
`;
