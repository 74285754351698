import React, { useEffect } from 'react';
import { db } from '../../lib/firebase';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import LogoWhiteHat from '../../Icons/Logo';
import { HeaderStylePrivate } from './stylesPrivate';
import { useAppSelector, useAppDispatch } from '../../redux/actions';

import { updatemsg } from '../../redux/reducers';
import Menu from './Menu';

interface HeaderPrivateProps {
  toggleDrawer?: () => void;
  toggleDrawerUser?: () => void;
}
type MsgTypes = {
  date: string;
  from: string;
  payload: string;
  time?: string;
  viewed?: boolean;
};

interface MsData {
  from: string;
  messages: MsgTypes[];
  to: string;
}

const HeaderPrivate = ({
  toggleDrawer,
  toggleDrawerUser,
}: HeaderPrivateProps) => {
  const me = useAppSelector((state) => state.logUser.value.user.email);
  const viewed = useAppSelector((state) => state.logUser.viewed);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!me) return;
    const q = query(collection(db, 'conversations'), where('to', '==', me));

    // Using onSnapshot to get real-time updates
    onSnapshot(q, (querySnapshot) => {
      let unreadCount = 0;

      querySnapshot.forEach((doc) => {
        const data = doc.data() as MsData;

        if (data) {
          const messages = data.messages || [];
          const userGet = data.from;

          messages.forEach((msg) => {
            if (!msg.viewed && userGet === me) {
              unreadCount++;
            }
          });
        }
      });

      dispatch(updatemsg(unreadCount)); // Update the viewed state
    });
  }, [me]);

  useEffect(() => {}, [viewed]);

  return (
    <HeaderStylePrivate>
      <div className="top">
        <div className="top__logoPlace">
          <LogoWhiteHat />
        </div>
        <span className="top__future">The Future of this generation</span>
      </div>
      <div className="top__cart">
        <div onClick={toggleDrawer}>
          <svg
            style={{ width: '30px' }}
            height="100%"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M8.5 19H8C4 19 2 18 2 13V8C2 4 4 2 8 2H16C20 2 22 4 22 8V13C22 17 20 19 16 19H15.5C15.19 19 14.89 19.15 14.7 19.4L13.2 21.4C12.54 22.28 11.46 22.28 10.8 21.4L9.3 19.4C9.14 19.18 8.77 19 8.5 19Z"
              stroke="#292D32"
              stroke-width="1.5"
              stroke-miterlimit="10"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="currentColor"
            />
            <path
              opacity="0.4"
              d="M15.9965 11H16.0054"
              stroke="#292D32"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              d="M11.9945 11H12.0035"
              stroke="#292D32"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              opacity="0.4"
              d="M7.99451 11H8.00349"
              stroke="#292D32"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div style={{ color: 'red', fontFamily: 'whitehat' }}>{viewed}</div>
        <div onClick={toggleDrawerUser}>
          <Menu />
        </div>
      </div>
    </HeaderStylePrivate>
  );
};

export default HeaderPrivate;
