import LogoWhiteHat from '../../Icons/Logo';
import { HeaderStyle } from './styles';

const Header = () => {
  return (
    <HeaderStyle>
      <div className="top">
        <div className="top__logoPlace">
          <LogoWhiteHat />
        </div>
        <span data-testid="slogan" className="top__future">
          The Future of this generation
        </span>
      </div>
    </HeaderStyle>
  );
};

export default Header;
