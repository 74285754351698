import styled from 'styled-components';

export const RegisterStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  fieldset {
    border-radius: 5px;
    border: none;
  }

  .termsSection {
    font-family: ${(props) => props?.theme.fonts.secondary};
  }

  .terms-checkbox {
    margin-left: 15px;
    font-size: 0.6rem;
  }

  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    margin-top: 10px;

    input {
      padding: 0.3rem;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      border-radius: 0.25rem;
      border: none;
      color: ${(props) => props?.theme.colors['bg-dark']};
      font-family: 'Ubuntu', sans-serif;

      &:active {
        outline: none;
        border: none;
      }

      &:focus {
        outline: 0;
      }
    }

    .button-login {
      margin-top: 10px;
      background-color: ${(props) => props?.theme.colors.primary};
      color: ${(props) => props?.theme.colors.white};
      font-family: ${(props) => props?.theme.fonts.secondary};
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 0.25rem;
      border: none;
    }
  }

  .invalid {
    opacity: 0.3;
  }

  .conditions {
    font-size: 0.8rem;
    text-align: center;
    margin: auto;
    padding: 4px;
    color: ${(props) => props?.theme.colors.secondary};
    border-bottom: 1px dashed ${(props) => props?.theme.colors.secondary};
  }
`;
