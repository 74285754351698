import { GeneralButton } from './generals';
import { LogoDiscord } from '../../Icons/List';

export default function Discord() {
  return (
    <GeneralButton>
      <div className="general-button">
        <div className="general-button__logo">
          <LogoDiscord x="100" />
        </div>
        <div className="general-button__label">Discord Channel</div>
      </div>
    </GeneralButton>
  );
}
