import { GeneralButton } from './generals';
import { Instagram } from '../../Icons/List';

export default function InstagramButton() {
  return (
    <GeneralButton>
      <div className="general-button">
        <div className="general-button__logo">
          <Instagram x="100" />
        </div>
        <div className="general-button__label">Follow us on Instagram</div>
      </div>
    </GeneralButton>
  );
}
