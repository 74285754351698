import { CookieStyle } from './CookieStyle';

import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
const CookiesTerms = () => {
  return (
    <CookieStyle>
      <Header />
      <div className="section-text">
        Introduction Our website, WHITEHAT4U.COM, uses cookies to improve your
        experience while navigating through our website. This policy explains
        how we use cookies and the choices you have regarding cookies. What are
        cookies? Cookies are small pieces of data stored on your device
        (computer or mobile device) when you visit a website. They are widely
        used to remember you and your preferences, either for a single visit
        (through a "session cookie") or for multiple repeat visits (using a
        "persistent cookie"). How do we use cookies? We use cookies to:
        Understand and save user's preferences for future visits. Compile
        aggregate data about site traffic and site interactions to offer better
        site experiences and tools in the future. We may also use trusted
        third-party services that track this information on our behalf.
      </div>
      <Footer />
    </CookieStyle>
  );
};

export default CookiesTerms;
