import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Firebase,
  db,
  getAuth,
  signInWithEmailAndPassword,
  storage,
} from '../lib/firebase';

//let notReaded = 1;

const theValueObject = {
  Firebase,
  db,
  getAuth,
  signInWithEmailAndPassword,
  storage,
};

type CartP = { cart: number; id: string | undefined };

interface UserState<T> {
  value: T;
  items: CartP[];
  watching: string;
  idx: string;
  viewed: number;
}

const initialState: UserState<any> = {
  value: { user: 'empty' },
  items: [],
  watching: 'empty',
  idx: 'empty',
  viewed: 0,
};

interface SerializableUser {
  uid: string;
}

const userSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    login(state, action: PayloadAction<SerializableUser>) {
      state.value = { user: action.payload };
    },
    logout(state) {
      state.value = { user: 'empty' };
      state.items = [];
      state.watching = 'empty';
      state.idx = 'empty';
      state.viewed = 0;
    },
    authApp(state) {
      state.value = { auth: theValueObject };
    },
    add(state, action: PayloadAction<CartP>) {
      state.items.push(action.payload);
    },
    removeItem(state, action: PayloadAction<string>) {
      state.items = state.items.filter((item) => item.id !== action.payload);
    },
    watching(state, action: PayloadAction<string>) {
      state.watching = action.payload;
    },
    idx(state, action: PayloadAction<string>) {
      state.idx = action.payload;
    },
    updatemsg(state, action: PayloadAction<number>) {
      state.viewed = action.payload;
    },
  },
});

export const {
  login,
  logout,
  add,
  removeItem,
  watching,
  authApp,
  idx,
  updatemsg,
} = userSlice.actions;
export default userSlice.reducer;
