const NotFound = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1 style={{ fontSize: '6rem' }}>404</h1>
      <p>This route doesn't exist.</p>
      <img src="/notfound.webp" alt="Not found" fetchpriority="high" />
      <p>When we lost our principles, we invite Chaos.</p>
      <p>Irving</p>
    </div>
  );
};

export default NotFound;
