import styled from 'styled-components';

export const StyleDrawerUser = styled.div`
  @keyframes example {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }

  .remove {
    margin-top: 10px;
    color: ${(props) => props?.theme.colors.original};
    &:hover{
      cursor: pointer;
      color: ${(props) => props?.theme.colors.red};
    }
  }

  .DrawerUser {
    padding: 15px;
    background: ${(props) => props?.theme.colors.original};
    width: 100%;
    color: white;
    position: fixed;
    z-index: 2;
    animation-name: example;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    overflow-y: auto;

    &__info {
      color: ${(props) => props?.theme.colors.black};
      line-height: 1.5;
      font-size: 18px;
      margin-bottom: 30px;
    }

    &__iconUser{
      width: 50px;
      height: 50px;
    }

    &__itemCart {
      margin-bottom: 30px;
      border: 1px solid #fff;
      border-radius: 3px;
      padding: 10px;

      &--price {
        margin-top: 10px;
        background-color: ${(props) => props?.theme.colors.red};
        color: ${(props) => props?.theme.colors.white};
        max-width: 75px;
        padding: 3px;
        border-right: 2px solid #fff;
      }

      &--title {
        color: white;
      }
    }

    &__total {
      color: ${(props) => props?.theme.colors.white};
      padding-left: 15px;
      border-left: 2px solid ${(props) => props?.theme.colors.red};
    }

    &__handyClose {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: ${(props) => props?.theme.colors.red};
      &:hover {
        cursor: pointer;
      }
    }

    &__icon {
      color: red;
      margin-top: 3px;
      margin-left: 5px;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .DrawerUser {
     border-left: 2px solid ${(props) => props?.theme.colors.grey};
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: white;
      p position: fixed;
      top: 0;
      bottom: 0; 
      right: 0;
      z-index: 2;
      animation-name: example;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  }
`;
