import { useAppSelector } from '../../redux/actions';
import { StyleMessage } from './StyleMessages';

import Chats from '../Chats/Chat';

interface DrawerProps {
  isDrawerOpen: boolean;
  onClose: () => void;
}

export default function DrawerMessages({ isDrawerOpen, onClose }: DrawerProps) {
  const sample = process.env.REACT_APP_EMAIL || 'content@whitehat4u.com';
  const {
    value: {
      user: { email: me },
    },
  } = useAppSelector((state) => state.logUser);

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isDrawerOpen && (
        <StyleMessage>
          <section className="Drawer">
            <div onClick={handleClose} className="Drawer__handyClose">
              <span>Close </span>
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  xmlSpace="preserve"
                  width="20"
                  viewBox="0 0 500 500"
                  fill="currentColor"
                  className="Drawer__icon">
                  <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
                </svg>
              </span>
            </div>
            <div className="Drawer-items">
              Pay members can see chats here. The chat could be deleted in some
              hours. Make over to mark as read the new messages.
            </div>
            <Chats withUser={sample} me={me} />
          </section>
        </StyleMessage>
      )}
    </>
  );
}
