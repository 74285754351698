import styled from 'styled-components';

export const PubicStyle = styled.div`
  .public-news {
    font-family: ${(props) => props?.theme.fonts.secondary};

    &__image {
      width: 100%;
      height: 100%;
    }
    &__title {
      font-size: 1.4rem;
      margin-bottom: 1rem;
      margin-top: 1rem;
      color: ${(props) => props?.theme.colors.black};
    }
  }
  .c-two {
    display: none;
  }

  @media screen and (min-width: 1030px) {
    display: flex;
    gap: 20px;
    font-size: 0.8rem;
    .public-news {
      width: 710px;

      &__image {
        width: 100%;
        height: auto;
      }

      &__title {
        font-size: 1.5rem;
        color: ${(props) => props?.theme.colors.black};
      }

      &__readMore {
        padding: 15px;
        background: ${(props) => props?.theme.colors.primary};
        color: ${(props) => props?.theme.colors.white};
        border: none;
        margin-top: 20px;
        margin-bottom: 20px;
        cursor: pointer;
        width: 120px;
        text-align: center;

        &:hover {
          color: ${(props) => props?.theme.colors.black};
          background-color: ${(props) => props?.theme.colors.white};
          transition-duration: 300ms;
        }
      }
    }

    .c-two {
      display: flex;
      width: 300px;
      background-color: ${(props) => props?.theme.colors.original};
      height: 800px;
      margin-right: 15px;
      margin-bottom: 15px;
      border-radius: 4px;
      position: sticky;
      top: 0;
    }
  }
`;
