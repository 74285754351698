import styled from 'styled-components';

export const StyleCard = styled.div`
  .Card {
    margin-bottom: 50px;
    padding: 30px 0px;

    :after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: ${(props) => props?.theme.colors['o-soft']};
      display: block;
      margin-top: 20px;
    }

    &__header {
      font-size: 12px;
    }

    &__content {
      font-family: ${(props) => props?.theme.fonts.primary};
      font-size: 15px;
    }

    &__price {
      padding: 5px 15px;
      border-radius: 4px;
      color: ${(props) => props?.theme.colors.white};
      position: relative;
      cursor: pointer;
      background-color: ${(props) => props?.theme.colors.black};

      &:hover {
        animation: Promo 400ms infinite alternate;
      }
    }

    @keyframes Promo {
      0% {
        background-color: ${(props) => props?.theme.colors['bg-dark']};
        color: white;
      }

      100% {
        background-color: ${(props) => props?.theme.colors.black};
        color: white;
      }
    }

    &__buttonPay {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${(props) => props?.theme.colors.white};
      background: ${(props) => props?.theme.colors.black};
      font-size: 1rem;
      border-radius: 3px;
      height: 30px;

      &:hover {
        cursor: pointer;
      }

      &--cart {
        background-color: ${(props) => props?.theme.colors.red};
        display: flex;
        align-self: center;
        width: 20%;
        margin-top: 20px;
      }

      &--label {
        background-color: ${(props) => props?.theme.colors.black};
        padding: 3px;
        display: flex;
        align-self: flex-start;
        width: 80%;
        height: 30px;
        padding-left: 10px;

        &:hover {
          cursor: pointer;
          background: ${(props) => props?.theme.colors['o-soft']};
        }
      }
    }
  }

  @media screen and (min-width: 500px) {
    .Card {
      width: 200px;
      flex-wrap: wrap;
      &__header {
        height: 90px;
      }
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .Image-Cards {
      height: 200px;
      object-fit: cover;
    }

    .Card {
      width: 300px;

      &__header {
        height: 80px;
      }

      &__content {
        font-family: ${(props) => props?.theme.fonts.primary};
        font-size: 15px;
        height: 110px;
      }
    }
  }
`;
