interface TryThis {
  x: string;
}
export const LogoDiscord = ({ x }: TryThis) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 127.14 96.36"
    width={`${x}%`}>
    <path
      fill="#fff"
      d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"
    />
  </svg>
);

export const Chevron = ({ x }: TryThis): JSX.Element => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#FFF"
      width={`${x}px`}>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5"
      />
    </svg>
  );
};

export const Pentagon = () => {
  return (
    <svg
      height="520"
      viewBox="60 10 292 280"
      xmlns="http://www.w3.org/2000/svg">
      <polygon
        points="60 13,110 48,92 110,30 110,13 48"
        stroke="none"
        fill="#ff5454"
        stroke-width="1"
        transform=" scale(2.5), translate(0 -25.5), rotate(20)"
      />
    </svg>
  );
};

export const Instagram = ({ x }: TryThis) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      data-name="Layer 1"
      viewBox="0 0 1000 1000"
      width={`${x}%`}>
      <path
        d="M295.42 6c-53.2 2.51-89.53 11-121.29 23.48-32.87 12.81-60.73 30-88.45 57.82S40.89 143 28.17 175.92c-12.31 31.83-20.65 68.19-23 121.42S2.3 367.68 2.56 503.46 3.42 656.26 6 709.6c2.54 53.19 11 89.51 23.48 121.28 12.83 32.87 30 60.72 57.83 88.45S143 964.09 176 976.83c31.8 12.29 68.17 20.67 121.39 23s70.35 2.87 206.09 2.61 152.83-.86 206.16-3.39S799.1 988 830.88 975.58c32.87-12.86 60.74-30 88.45-57.84s44.77-55.74 57.48-88.68c12.32-31.8 20.69-68.17 23-121.35 2.33-53.37 2.88-70.41 2.62-206.17s-.87-152.78-3.4-206.1-11-89.53-23.47-121.32c-12.85-32.87-30-60.7-57.82-88.45S862 40.87 829.07 28.19c-31.82-12.31-68.17-20.7-121.39-23S637.33 2.3 501.54 2.56 348.75 3.4 295.42 6m5.84 903.88c-48.75-2.12-75.22-10.22-92.86-17-23.36-9-40-19.88-57.58-37.29s-28.38-34.11-37.5-57.42c-6.85-17.64-15.1-44.08-17.38-92.83-2.48-52.69-3-68.51-3.29-202s.22-149.29 2.53-202c2.08-48.71 10.23-75.21 17-92.84 9-23.39 19.84-40 37.29-57.57s34.1-28.39 57.43-37.51c17.62-6.88 44.06-15.06 92.79-17.38 52.73-2.5 68.53-3 202-3.29s149.31.21 202.06 2.53c48.71 2.12 75.22 10.19 92.83 17 23.37 9 40 19.81 57.57 37.29s28.4 34.07 37.52 57.45c6.89 17.57 15.07 44 17.37 92.76 2.51 52.73 3.08 68.54 3.32 202s-.23 149.31-2.54 202c-2.13 48.75-10.21 75.23-17 92.89-9 23.35-19.85 40-37.31 57.56s-34.09 28.38-57.43 37.5c-17.6 6.87-44.07 15.07-92.76 17.39-52.73 2.48-68.53 3-202.05 3.29s-149.27-.25-202-2.53m407.6-674.61a60 60 0 1 0 59.88-60.1 60 60 0 0 0-59.88 60.1M245.77 503c.28 141.8 115.44 256.49 257.21 256.22S759.52 643.8 759.25 502 643.79 245.48 502 245.76 245.5 361.22 245.77 503m90.06-.18a166.67 166.67 0 1 1 167 166.34 166.65 166.65 0 0 1-167-166.34"
        style={{
          fill: '#fff',
        }}
        transform="translate(-2.5 -2.5)"
      />
    </svg>
  );
};

export const Woman = ({ x }: TryThis) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={x}
      height="100%"
      version="1.1"
      viewBox="0 0 512.001 512.001"
      xmlSpace="preserve">
      <path
        fill="#CF8575"
        d="M257.827 232.229H60.953v120.686c0 26.93 87.326 48.762 195.048 48.762l195.048-169.448H257.827z"></path>
      <path
        fill="#BE6054"
        d="M256.61 232.229v169.445c107.441-.082 194.438-21.879 194.438-48.759V232.229H256.61z"></path>
      <path
        fill="#F2CDB7"
        d="M348.684 408.439a122.511 122.511 0 00-21.665-19.906c-19.998-14.36-44.52-22.819-71.018-22.819s-51.02 8.459-71.019 22.819a122.584 122.584 0 00-21.665 19.906c-17.527 20.497-28.376 46.874-29.172 75.764a278.558 278.558 0 0048.712 18.139c23.32 6.285 47.837 9.659 73.143 9.659s49.822-3.373 73.143-9.659c.475-.128.952-.255 1.428-.386 3.618-.996 7.206-2.06 10.763-3.196a279.238 279.238 0 0018.286-6.572c3.662-1.457 7.3-2.964 10.884-4.569a278.774 278.774 0 007.352-3.416c-.795-28.891-11.645-55.266-29.172-75.764z"></path>
      <path
        fill="#E1A996"
        d="M287.196 443.739a42.724 42.724 0 00-11.716 1.426 75.587 75.587 0 01-38.958 0 42.72 42.72 0 00-11.716-1.426c-23.084.38-41.947 20.451-41.947 43.538v-5.754 20.818c23.32 6.285 47.837 9.659 73.143 9.659s49.822-3.373 73.143-9.659v-20.818 5.753c-.002-23.086-18.865-43.156-41.949-43.537z"></path>
      <path
        fill="#F2CDB7"
        d="M163.318 408.439c-17.527 20.497-28.376 46.874-29.172 75.764a278.558 278.558 0 0048.712 18.139v-20.819c0-26.621-7.115-51.58-19.54-73.084z"></path>
      <g fill="#E1A996">
        <path d="M348.684 408.439a145.807 145.807 0 00-8.92 18.286c-6.844 16.925-10.62 35.419-10.62 54.799v20.818c.475-.128.952-.255 1.427-.386 3.618-.996 7.206-2.06 10.763-3.196a279.238 279.238 0 0018.286-6.572c3.662-1.457 7.3-2.964 10.884-4.569a278.774 278.774 0 007.352-3.416c-.795-28.891-11.645-55.266-29.172-75.764z"></path>
        <path d="M353.524 487.619v6.907a303.987 303.987 0 006.095-2.339c3.662-1.457 7.3-2.964 10.884-4.569a278.774 278.774 0 007.352-3.416c-.795-28.891-11.644-55.267-29.172-75.764a122.511 122.511 0 00-21.665-19.906c-19.999-14.359-44.52-22.818-71.018-22.818s-51.02 8.459-71.019 22.819a120.315 120.315 0 00-4.855 3.689l.123.151c18.628 22.956 46.24 36.122 75.752 36.122 16.205 0 32.132-4.074 46.264-11.681 8.137-4.38 18.265-2.832 24.603 3.895a99.152 99.152 0 017.97 9.607c12.226 16.769 18.686 36.583 18.686 57.303z"></path>
      </g>
      <path
        fill="#E1A996"
        d="M367.63 122.526l-.012-.011c-23.78-15.504-58.453-20.135-90.468-12.083-3.412.857-11.101 2.843-16.08 4.131l-16.073-4.137-.124-.032c-24.526-6.131-50.761-1.727-71.979 12.081-20.912 13.607-35.556 35.763-40.179 60.788-5.054 27.357-17.54 90.693-25.665 131.697a171.364 171.364 0 0029.613 38.641 171.37 171.37 0 0029.748 23.295c26.036 16.095 56.726 25.389 89.589 25.389 77.35 0 142.67-51.463 163.618-122.013l.5-97.562-52.488-60.184z"></path>
      <path
        fill="#F2CDB7"
        d="M390.096 213.333c0-2.722-.071-5.452-.197-8.182-8.177 1.51-16.03-3.894-17.542-12.071l-.586-3.176c-2.809-15.209-11.614-28.612-24.159-36.775-11.611-7.556-34.698-13.982-61.547-7.23-5.107 1.284-20.39 5.249-20.39 5.249l-4.575 1.187-25.159-6.476c-14.683-3.653-30.39-1.004-43.103 7.269-12.546 8.164-21.353 21.569-24.163 36.777-6.788 36.747-26.83 137.638-32.016 163.695a171.37 171.37 0 0029.748 23.295c18.07 4.613 36.048 7.103 53.022 7.103 94.257.002 170.667-76.408 170.667-170.665z"></path>
      <g fill="#474641">
        <circle cx="195.048" cy="231.619" r="18.286"></circle>
        <circle cx="316.953" cy="231.619" r="18.286"></circle>
      </g>
      <g fill="#BE6054">
        <path d="M316.937 188.62c-6.652 0-13.3-1.051-19.761-3.155-4.801-1.563-7.426-6.723-5.864-11.524 1.564-4.803 6.724-7.428 11.525-5.864 9.22 3.003 18.984 3 28.236-.005a9.143 9.143 0 015.65 17.39 63.874 63.874 0 01-19.786 3.158zM195.032 188.62c-6.652 0-13.3-1.051-19.761-3.155-4.801-1.563-7.426-6.723-5.864-11.524 1.564-4.803 6.73-7.428 11.525-5.864 9.22 3.003 18.984 3 28.236-.005a9.143 9.143 0 015.65 17.39 63.874 63.874 0 01-19.786 3.158z"></path>
      </g>
      <g fill="#E1A996">
        <path d="M168.229 265.143h-12.19a9.142 9.142 0 01-9.143-9.143 9.142 9.142 0 019.143-9.143h12.19a9.142 9.142 0 019.143 9.143 9.142 9.142 0 01-9.143 9.143zM355.963 265.143h-12.19A9.142 9.142 0 01334.63 256a9.142 9.142 0 019.143-9.143h12.19a9.142 9.142 0 019.143 9.143c-.001 5.049-4.093 9.143-9.143 9.143z"></path>
      </g>
      <path
        fill="#BE6054"
        d="M444.903 167.817c-2.102-38.39-18.066-75.847-44.956-105.468-28.308-31.184-67.728-52.536-110.318-59.588 0 0-21.438-2.761-33.629-2.761l-36.571 85.333 36.775 48.115 20.524-5.283c19.688-4.923 40.715-1.41 57.693 9.638 16.976 11.046 28.492 28.863 32.171 48.781 7.851 42.498 33.202 169.65 33.202 169.65l32.879 3.385c8.18-19.5 12.484-40.608 12.484-62.326v-10.255l.201-110.897-.455-8.324z"></path>
      <g fill="#CF8575">
        <path d="M216.325 3.944C240.153-1.219 256 .287 256 .287l.204 30.107v103.055l-20.646-5.314c-19.59-4.897-40.597-1.379-57.572 9.668-16.976 11.047-28.492 28.865-32.171 48.783-7.851 42.498-33.202 169.649-33.202 169.649l-32.93 3.385c-8.543-19.853-13.04-41.424-13.04-63.69v-8.892l.408-110.894.455-8.326c2.102-38.39 18.066-75.847 44.956-105.468 26.872-29.604 63.757-50.345 103.863-58.406M333.388 68.158l-26.603 26.603c-2.807 2.807-6.457 4.419-10.279 4.54-3.513.11-7.589.406-12.115.875l-.084.005c-.319 0-.674-.293-.674-.712V36.966c0-1.929.755-3.728 2.019-4.813 1.115-.958 2.398-1.442 3.814-1.442.397 0 .805.041 1.208.123a166.766 166.766 0 0138.619 12.845c4.459 2.117 7.502 6.142 8.349 11.041.861 4.977-.689 9.875-4.254 13.438z"></path>
      </g>
      <path
        fill="#BE6054"
        d="M237.777 101.165a81.798 81.798 0 00-19.819-2.432c-16.033 0-31.579 4.601-44.956 13.307-9.211 5.994-17.401 14.674-23.682 25.1-7.965 13.223-8.825 58.058-8.825 74.311v3.629c3.83-19.608 25.611-66.373 42.369-77.278 16.975-11.047 37.981-14.565 57.572-9.668l15.565 4.006 10.971-14.502-10.971-11.782-18.224-4.691z"></path>
      <path
        fill="#F2CDB7"
        d="M91.884 182.711a54.596 54.596 0 00-24.836-5.949c-30.297 0-54.857 24.56-54.857 54.857s24.56 54.857 54.857 54.857a54.6 54.6 0 0025.334-6.204l-.498-97.561z"></path>
      <g fill="#E1A996">
        <path d="M444.953 176.762a54.599 54.599 0 00-24.836 5.949l-.5 97.562a54.608 54.608 0 0025.335 6.204c30.297 0 54.857-24.56 54.857-54.857s-24.559-54.858-54.856-54.858z"></path>
        <circle cx="67.048" cy="231.619" r="18.286"></circle>
      </g>
      <circle cx="444.953" cy="231.619" r="18.286" fill="#CF8575"></circle>
      <g fill="#5B5751">
        <circle cx="195.048" cy="231.619" r="18.286"></circle>
        <circle cx="316.953" cy="231.619" r="18.286"></circle>
      </g>
      <path
        fill="#BE6054"
        d="M256.001 284.518c-7.326 0-14.214-2.854-19.395-8.034a9.141 9.141 0 01.001-12.93c3.571-3.569 9.36-3.569 12.929 0a9.081 9.081 0 006.465 2.678 9.083 9.083 0 006.465-2.678c3.572-3.571 9.361-3.571 12.929 0a9.141 9.141 0 01.001 12.93c-5.181 5.181-12.069 8.034-19.395 8.034z"></path>
    </svg>
  );
};

export const Man = ({ x }: TryThis) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={x}
      height="100%"
      version="1.1"
      viewBox="0 0 511.998 511.998"
      xmlSpace="preserve">
      <path
        fill="#868075"
        d="M321.258 410.775l-140.47-27.811a150.037 150.037 0 00-28.06 21.184l23.624 96.608c25.284 7.315 52.006 11.243 79.648 11.243 21.371 0 42.192-2.345 62.225-6.789a283.51 283.51 0 0017.423-4.454 283.481 283.481 0 0030.217-10.625 285.577 285.577 0 0034.333-17.075l-78.94-62.281z"></path>
      <path
        fill="#706B63"
        d="M383.876 434.841a150.086 150.086 0 00-52.662-51.877H180.788a148.447 148.447 0 00-6.112 3.771c18.344 25.011 47.931 41.26 81.324 41.26 16.411 0 31.901-3.928 45.593-10.887 4.893-2.487 10.761-2.06 15.362.933l.284.185a40.698 40.698 0 0118.409 34.046v48.484a284.282 284.282 0 0030.216-10.624 285.336 285.336 0 0013.113-5.848c.027-.014.056-.026.083-.039a290.53 290.53 0 004.046-1.97l.307-.151a276.436 276.436 0 003.763-1.912c.177-.091.355-.179.531-.271a275.04 275.04 0 003.483-1.843c.25-.134.502-.265.752-.4 1.104-.596 2.2-1.206 3.297-1.816.289-.162.581-.319.87-.48 1.342-.754 4.06-2.327 4.088-2.343l-16.321-38.218z"></path>
      <path
        fill="#868075"
        d="M152.728 404.148c-19.362 18.547-33.743 42.249-40.927 68.909a285.47 285.47 0 0034.334 17.075l30.218-56.872a149.878 149.878 0 00-23.625-29.112z"></path>
      <path
        fill="#F8E5B5"
        d="M160.876 462.388a150.039 150.039 0 00-32.739-27.535 148.426 148.426 0 00-16.335 38.204 285.47 285.47 0 0034.334 17.075l14.74-27.744z"></path>
      <path
        fill="#ECCE93"
        d="M354.522 458.706a111.303 111.303 0 0111.344 31.425 285.577 285.577 0 0034.333-17.074 148.403 148.403 0 00-16.337-38.202 149.973 149.973 0 00-29.34 23.851z"></path>
      <path
        fill="#706B63"
        d="M146.136 490.131a283.938 283.938 0 0030.218 10.624v-67.498c-15.127 15.3-25.875 34.935-30.218 56.874z"></path>
      <path
        fill="#F8E5B5"
        d="M257.245 60.246l-159.352 87.72a219.501 219.501 0 00-9.437 27.868 55.746 55.746 0 00-25.354-6.073c-30.93 0-56.002 25.073-56.002 56.002s25.073 56.002 56.002 56.002a55.75 55.75 0 0019.51-3.516c.209-.078.418-.156.625-.235a55.082 55.082 0 002.733-1.134c.057-.025.112-.052.168-.078.88-.398 1.747-.821 2.602-1.263.073-.039.151-.07.224-.108 11.758 39.601 37.214 73.307 70.874 95.632l34.349 12.335 117.191-.83L446.406 222.65 257.245 60.246z"></path>
      <path
        fill="#ECCE93"
        d="M454.632 170.051a56.58 56.58 0 00-5.735-.29 55.74 55.74 0 00-25.354 6.073 217.08 217.08 0 00-6.059-19.216C397.98 103.384 357.676 56.606 307.747 36.762 291.397 30.264 274.02 26.645 256 26.645c-63.744 0-119.485 45.158-149.857 103.616a227.048 227.048 0 00-8.25 17.705v28.421c.02 7.355 4.415 14.315 11.651 17.252 9.555 3.877 20.441-.724 24.317-10.277 14.597-35.961 35.398-58.19 60.161-64.283 20.588-5.066 40.841 2.014 51.78 11.279 28.679 24.288 74.126 57.416 107.822 70.85 10.115 4.032 20.65 6.042 31.167 6.042h.093c4.906-.006 8.805 4.099 8.397 8.988l-1.465 17.572c-13.456 82.846-85.327 146.093-171.973 146.085-19.169-.001-39.59-3.109-60.003-8.828 27.557 18.278 60.612 28.928 96.159 28.928 78.965 0 145.648-52.538 167.034-124.561 8.73 4.555 18.801 6.893 29.476 6.22 28.067-1.772 50.741-24.614 52.301-52.693 1.69-30.422-20.911-55.933-50.178-58.91z"></path>
      <path
        fill="#644D2D"
        d="M392.9 226.014c0-.082-.005-.163-.005-.244v-.006c.175-3.341-1.527-6.581-4.503-8.112-7.092-3.649-14.165 1.405-14.165 8.112v.006c0 20.623-16.719 37.329-37.341 37.329H321.33c-10.307 0-18.661-8.356-18.661-18.661v-.012c0-15.458-12.531-27.989-27.989-27.989h-37.347c-15.464 0-28.001 12.537-28.001 28.001 0 10.307-8.356 18.661-18.661 18.661h-15.556c-20.623 0-37.341-16.706-37.341-37.329v-.006c.175-3.341-1.527-6.581-4.503-8.112-7.092-3.649-14.165 1.405-14.165 8.112v.006c0 21.685-12.334 40.47-30.363 49.771.073-.039.151-.07.224-.108 11.758 39.601 37.214 73.307 70.874 95.632 27.557 18.278 60.612 28.928 96.159 28.928 78.965 0 145.649-52.538 167.034-124.561.073.039.151.071.224.108-17.961-9.265-30.265-27.945-30.358-49.526z"
        enableBackground="new"
        opacity="0.2"></path>
      <circle cx="63.103" cy="225.763" r="18.667" fill="#ECCE93"></circle>
      <circle cx="448.891" cy="225.763" r="18.667" fill="#E1B872"></circle>
      <path
        fill="#E2C35A"
        d="M207.455 80.052L207.452 80.049 207.45 80.052z"></path>
      <path
        fill="#D9B43A"
        d="M447.302 143.22c-11.89 35.945-51.596 54.667-86.764 40.645-33.584-13.39-78.859-47.588-102.671-67.755-29.304-24.817-102.015-36.566-141.302 60.231-5.307 13.073-19.005 4.053-28.249-.808 0 0-11.742-87.801 67.076-108.541 0 0-18.554-18.257 9.758-46.569s101.012-29.038 162.615 9.542S447.302 143.22 447.302 143.22z"></path>
      <path
        fill="#BB9430"
        d="M410.318 147.01c-33.584-13.39-78.859-47.59-102.671-67.755-20.737-17.562-63.211-28.567-100.196.795l-1.388.102a148.917 148.917 0 00-118.048 74.152c-.859 12.546.301 21.229.301 21.229 9.244 4.861 22.942 13.881 28.249.808 18.664-45.985 44.87-67.462 70.384-74.687 14.65-4.149 29.069-3.605 41.702-.289 11.676 3.065 21.827 8.489 29.216 14.746 23.812 20.166 69.087 54.366 102.671 67.755a65.344 65.344 0 0018.349 4.383c29.63 2.681 58.562-15.253 68.413-45.023-12.512 5.663-25.606 8.318-36.982 3.784z"></path>
      <path
        fill="#E2C35A"
        d="M109.239 147.991c23.469-43.673 53.477-59.659 75.863-65.167a95.752 95.752 0 0122.347-2.773l.002-.002c-11.171-6.184-27.67-11.381-52.059-13.059-54.036 14.221-65.505 59.956-67.377 87.313l.157.083c7.585 4.011 17.005 1.162 21.067-6.395z"></path>
      <g fill="#474641">
        <path d="M256 272.432c-15.44 0-28.001-12.561-28.001-28.001a9.334 9.334 0 0118.668 0c0 5.147 4.186 9.334 9.334 9.334 5.147 0 9.334-4.186 9.334-9.334a9.334 9.334 0 0118.668 0c-.002 15.441-12.562 28.001-28.003 28.001z"></path>
        <circle cx="193.781" cy="225.763" r="18.667"></circle>
        <circle cx="318.231" cy="225.763" r="18.667"></circle>
      </g>
      <g fill="#BB9430">
        <path d="M336.892 197.763h-37.335a9.334 9.334 0 010-18.668h37.335a9.334 9.334 0 010 18.668zM175.108 197.763h37.335a9.334 9.334 0 000-18.668h-37.335a9.334 9.334 0 000 18.668z"></path>
      </g>
    </svg>
  );
};
