import { TermsStyle } from './TermsStyle';
import Header from '../../components/header/Header';
import Footer from '../../components/footer/Footer';
import Payments from './Payments';
const Terms = () => {
  return (
    <TermsStyle>
      <Header />
      <Payments />

      <section>
        <div className="container">
          <h1>Terms and Conditions</h1>
          <div className="divider"></div>
        </div>
        <p>
          Reading the terms and conditions outlined in this document is a
          requirement. These terms are acknowledged and accepted upon user
          registration on the site and through the use of our web application.
          To access a course, creating a user account with the requested
          personal information is mandatory. Validation of your email is
          required through a verification link sent to you. If you do not find
          the email in your inbox, please check your Spam or other folders. This
          step is necessary to activate your account. Failure to validate your
          email will result in the permanent deletion of your registration.
        </p>
        <p>
          The ability to change the password on the social network does not hold
          whitehat4u.com accountable for any actions taken by the user with
          their account or in the event that the password is obtained by third
          parties. Password change requests can only be processed and sent to
          the email address registered with us. It is not possible to send a
          password reset link to an alternate email address or to a third party
        </p>
        <p>
          Links on the site and the payment method become effective after they
          have been reviewed, verified, and confirmed. Subject to this review,
          they must be activated and acknowledged in a confirmation reply email
          as mentioned above.
        </p>

        <h2>Termination of website application</h2>
        <ol>
          <li>
            Whitehat4u may terminate the provision of the Website access service
            at any time for an important reason like: economic unprofitability
            of the operation of the Website, decision by a court or
            corresponding authority or making another kind of marketing activity
            with 30 days notification for the active users
          </li>
        </ol>
        <br />
        <br />
        <h2>Complaint procedure</h2>
        <ol>
          <li>
            <p>
              Not issue of VAT Invoices - Use of our services by a privileged
              entrepreneur (defined as an individual making purchases related to
              their business but not of a professional nature for the buyer)
              does not qualify for professional use.
            </p>
          </li>
          <li>
            In case you find some issues / troubles in the website, please
            inform by the channel on discord app Direct Messages: server
            whitehat4u support:
            <a
              style={{
                backgroundColor: 'black',
                color: 'white',
                borderRadius: '4px',
                padding: '4px',
                marginLeft: '5px',
              }}
              href="https://discord.gg/n3V5XThUcd"
              target="_blank"
              rel="noreferrer">
              server whitehat4u support
            </a>
          </li>
          <li>
            The access to internet and other third services you might need is
            not included in the price you pay for access to the content in each
            program or the platform in general.
          </li>
        </ol>
        <br />
        <br />
        <h2>Personal Data</h2>
        <p>
          Consent to the processing by Whitehat4u.com my give us your data
          voluntarily, contained in this form, page and / or in other documents
          provided by me, in order to conduct and administer future selection
          processes with my participation, including receiving information,
          emails SMS or contact. The administrator of Users personal data is
          whitehat4u based in, ul. Chelmoskiego 72B, 30-340 Krakow.
          <ol>
            <li>
              Right to be forgotten: The User shall have the right to review and
              change their personal data at any time and also to request the
              Controller to immediately erase them via platform. After 30 days
              the user requested, the user will be permanently deleted, and will
              have no rights to register again in our platform with the same
              credentials.
            </li>
            <li>
              The Administrator has appointed a Data Protection Inspector, who
              can be contacted via the e-mail address: content@whitehat4u.com
            </li>
            <li>
              The Controller shall collect and process the personal data of the
              Users listed below via the Service and other means of
              communication that have been provided during the processes of
              registration in the Service: first name and surname, address of
              residence, e-mail address, telephone number, ..., etc.
            </li>

            <li>
              The processing of your personal data allows us to provide
              services, among others, maintaining your account, executing your
              orders, keeping contact in relation of the performance of the
              agreement, and also sending marketing information (newsletters
              included).
            </li>

            <li>
              The personal data shall be stored for the term of the agreement
              and for a time that is compliant with the applicable regulations,
              including a limitation period for claims and tax liabilities. The
              personal data to the processing of which you have expressed your
              consent shall be stored until you have withdrawn your consent.
            </li>
            <li>
              You may submit requests related to the scope of personal data
              processing by email to content@whitehat4u.com
            </li>
            <li>
              Your personal data may be provided to a payment operator and a
              courier service. Profiling of personal data will be used to
              personalize and improve the services we offer, including providing
              targeted advertisements and offers based on your preferences and
              behaviors in the platform or by email, optimizing our website
              functionality, and enhancing user experience.
            </li>
          </ol>
        </p>
        <br />
        <h2>Cookies</h2>
        <p>
          We use essential cookies and similar tools that are necessary to
          provide our site and services. We use performance cookies to collect
          anonymous statistics so we can understand how customers use our site
          and make improvements. Essential cookies cannot be deactivated, but
          you can click “Customize cookies” to decline performance cookies.
        </p>
        <p>
          If you agree, Whitehat4u and approved third parties will also use
          cookies to provide useful site features, remember your preferences,
          and display relevant content, including relevant advertising. To
          continue without accepting these cookies, click “Continue without
          accepting.”
        </p>
        <br />
        <h2>Usage License</h2>
        <p>
          No user holds any intellectual property rights to the courses or
          content on the site, whether for paid or free programs, courses, or
          publications provided by <strong>whitehat4u.com</strong> . Payments
          made by users do not grant them any rights to use this information for
          external profit-making, projects, or educational purposes without
          prior authorization. This includes uploading to other platforms or any
          illegal activities such as downloading or screen recording of the
          related material.
        </p>
        <br />
        <h2>Change Password</h2>
        <p>
          If you lost access to your account, we aren't unable to restore
          directly. You must to request change password by emailing
          content@whitehat4u.com and by the associated email of your account.
          Then, you will receive a special and private link generated by our
          system requesting change password. This feature is not enable directly
          in the forms to avoid bots usage our services and system.
        </p>
        <br />
        <h2>Unauthorized Use</h2>
        <p>
          You are not permitted to download, copy, or redistribute videos in any
          other medium, be it digital or physical. Should such actions occur, we
          will initiate legal proceedings and permanently revoke your access to
          the site. Consequently, we review your information thoroughly upon
          registration.
          <ol>
            <li>
              If you sabotage the website, or break the civil code we will take
              actions in the court.
            </li>
            <li>
              Breaking ANY of the description in this document, we will take
              legal actions.
            </li>
          </ol>
        </p>
        <br />
        <p>
          We permit the sharing of videos from our official YouTube channel by
          using the specific URL for each video. This is distinct from
          downloading the videos and uploading them to another channel, an
          action considered fraudulent and in violation of both YouTube's
          ID_CONTENT policies and those of WHITEHAT4U. We will take legal action
          against such violations.
        </p>
        <br />
        <p>
          Any threats or offensive behavior towards our teachers or users,
          misuse of the information system, derogatory comments—including those
          made on social media—, or any form of verbal or physical aggression
          will be treated with the utmost seriousness. Such actions will lead to
          the permanent revocation of user access and the initiation of
          appropriate legal measures.
        </p>
        <br />
        <h2>Intellectual Property</h2>
        <p>
          All courses, content, photographs, and materials on the site are the
          exclusive property of <strong> whitehat4u.com </strong> and not of the
          user. Under no circumstances will whitehat4u.com be held responsible,
          either directly or indirectly, for any damages incurred before,
          during, or after a program or course. This also applies to any
          inability to access a program or course.
        </p>
        <br />
        <h2 id="privacy">Privacy</h2>
        <p>
          We reserve the right to modify our policies, including the Terms and
          Conditions, without prior notice. By agreeing to these terms, you also
          consent to receive email communications from us, which may include
          advertisements or promotional notices. You retain the right to opt-out
          of these communications at any time by sending an email to:
          content@whitehat4u.com subject: @username
        </p>
        <ol>
          <li>
            Doing click in the registration form you accept all rules described
            here. “I hereby express my consent to the processing of personal
            data” or “I hereby accept the Rules and Regulations and the Privacy
            Policy.”
          </li>
          <li>
            We periodically make exercises to keep users data secure in our
            site.
          </li>
          <li>
            You have the right to lodge a complaint with a supervisory authority
            that deals with the protection of personal data by the President of
            the Personal Data Protection Office.
          </li>
        </ol>
        <p>
          Except in the case of a federal order, your personal information will
          not be shared with third parties. Should there be a need to exercise
          this exception, we will inform you via email. Maintaining the privacy
          of your data is a paramount rule in our community, provided that the
          user does not compromise or disclose their own information, lose their
          password, or grant access to others. Regarding the use of the YouTube
          platform: You are allowed to share videos using the share icon
          provided in the videos, in compliance with the unauthorized use policy
          outlined earlier.
        </p>
        <br />
        <h2>Languages</h2>
        <p>
          Each course or program is accompanied by a detailed description,
          including the language in which it is offered. Hangouts or live videos
          are presented in their original language. English and Spanish are
          currently the primary languages for some videos, based on the majority
          of our users.{' '}
        </p>
        <p>
          Please be aware that if, during a live session, your comments are
          deemed inappropriate for the class or are aimed at disrupting the
          session, including the use of offensive language of any nature,
          WHITEHAT4U reserves the right to ban your account and take further
          actions to prevent your access to the site, including related channels
          such as Discord and Instagram.
        </p>
        <br />
        <h2>Contact</h2>
        <ol className="payments-request">
          <li>Jhon Guerrero Santos whitehat4u</li>
          <li>NIP: 6772476013</li>
          <li>REGON: 521603034</li>
          <li>content@whitehat4u.com</li>
          <li></li>
        </ol>
        <br />
        <h2>Registration details:</h2>
        <ol>
          <li>
            Register name: Central Registration And Information On Business
            (CRIB)
          </li>
          <li>
            Regulatory Authority: Ministry of Economic Development and
            Technology
          </li>
        </ol>
      </section>
      <Footer />
    </TermsStyle>
  );
};

export default Terms;
