import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../redux/actions';

import { SideBarStyles } from './SideBarStyles';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';
import { createComponent } from '@lit/react';
import useUser from '../../hooks/use-user';

type MyElementComponentProps = any;

export const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

interface Item {
  id: number;
  lesson: string;
  description: string;
  linkId: string;
}

const SideBar: React.FC = () => {
  const selectedWatching = useAppSelector((state) => state.logUser.watching);
  const [loading, setLoading] = React.useState(true);
  const [selectedWatchingData, setSelectedWatchingData] = React.useState<
    Item[]
  >([]);
  const [selected, setSelected] = React.useState<Item | undefined>(undefined);
  const [isValid, setIsvalid] = useState<boolean>(false);

  const {
    userdb: { registeredOn },
    loading: userLoading,
  } = useUser();

  useEffect(() => {
    if (registeredOn !== undefined) {
      let verified = registeredOn.includes(selectedWatching);

      setIsvalid(verified);
    }
  }, [registeredOn, selectedWatching]);

  useEffect(() => {
    const fectData = async () => {
      try {
        const { program } = await import(
          `../../pages/api/programs/${selectedWatching}.tsx`
        );

        setSelectedWatchingData(program);
        setSelected(program[0]);
        setLoading(false);
      } catch (error) {
        setLoading(true);
      }
    };

    fectData();
  }, [selectedWatching]);

  const gotheVideo = (item: Item) => {
    window.scrollTo(0, 0);
    setSelected(item);
  };

  return (
    <SideBarStyles>
      {!loading ? (
        <>
          {selected !== undefined && isValid && !userLoading ? (
            <>
              <div className="videoSelected">
                <iframe
                  src={`https://player.vimeo.com/video/${selected.linkId}?title=0&amp;byline=0&amp;portrait=0&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  allow="autoplay; fullscreen; picture-in-picture"
                  title="whitehat4u"
                  className="videoProjector"></iframe>
                <div className="videoSelected__info">
                  <h2>{selected.lesson}</h2>
                  <p>{selected.description}</p>
                </div>
              </div>

              {selectedWatchingData.map((item) => (
                <div key={item.lesson} className="sidebar-container">
                  <div
                    className={`sidebar-container--id ${
                      selected.id === item.id ? 'current' : ''
                    }`}>
                    0{item.id}
                  </div>
                  <div className="menu-item-a">
                    <h3> {item.lesson} </h3>
                    <div className="sidebar-container--description">
                      description:
                    </div>
                    {item.description}
                  </div>

                  <div className="menu-item-b">
                    <div className="menu-item-b__content">
                      <div onClick={() => gotheVideo(item)}>
                        {item.id === selected.id ? (
                          ''
                        ) : (
                          <MyElementComponent
                            key={item.id}
                            labelTemplate="Watch video"
                            colors="wh4u"
                            type="button"></MyElementComponent>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <div className="notPermited">
              <h2>You need purchase the program in order to access it.</h2>
            </div>
          )}
        </>
      ) : (
        'loading ....'
      )}
    </SideBarStyles>
  );
};

export default SideBar;
