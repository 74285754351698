export const ProgramList = [
  {
    id: 1,
    title: 'CSS Animations',
    content:
      'Master CSS Animations by learning about transitions and keyframes animations. Discover how to create smooth and visually engaging transitions, and use keyframes to define complex animation sequences. CSS allows animation of HTML elements without using JavaScript. *Requires knoledge of HTML and CSS',
    episodes: [
      { id: 1, title: 'CSS Transitions animations 23:18 min' },
      { id: 2, title: 'Keyframes animations 26:27 min' },
      { id: 3, title: 'Animation compositions 08:11 min' },
      { id: 4, title: 'CSS Animations Scroll - September 14th 2024' },
    ],
    image: '/webComponents',
    language: 'EN',
    price: '65 PLN',
  },
  {
    id: 2,
    title: 'Ciberseguridad Free',
    content:
      'prende los conceptos por medio de ejemplos no reales los diferentes ataques informaticos a empresas, personas y dispoditivos digitales',
    episodes: [
      { id: 1, title: 'Que es la Esteganografia' },
      { id: 2, title: 'Como funciona un ataque DDoS' },
      { id: 3, title: 'Google hacking' },
    ],
    image: '/cibersecurity',
    language: 'EN',
    price: 'Free',
  },
];
