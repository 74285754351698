import React, { useEffect, useState } from 'react';
import { StyleDrawerUser } from './StyleDrawerUser';
import SingOutButton from '../forms/SingOut';
import { useAppSelector } from '../../redux/actions';
import { getCurrentTime, getCurrentDate } from '../../utility/dateToday';
import { Woman, Man } from '../../Icons/List';

interface DrawerPropsUser {
  isOpenUser: boolean;
  onCloseUser: () => void;
}

interface TimeOf {
  year: string;
  month: string;
  day: string;
}

export default function DrawerUser({
  isOpenUser,
  onCloseUser,
}: DrawerPropsUser) {
  const [timeOf, setTimeOf] = useState<TimeOf>({
    year: 'YY',
    month: 'MM',
    day: 'DD',
  });
  const info = useAppSelector((state) => state.logUser.value);
  const createdUser = info?.user?.createdAt;

  useEffect(() => {
    const timestamp = Number(createdUser);
    const date = new Date(timestamp);
    const year = date.getFullYear().toString();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    if (createdUser === undefined || createdUser === 'undefined') {
      window.location.reload();
      console.log('reload');
    }

    if (!createdUser) {
      return;
    }

    if (isNaN(date.getTime())) {
      return;
    }

    setTimeOf({ year, month, day });
  }, [createdUser]);

  const handleCloseUser = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    e.stopPropagation();
    onCloseUser();
  };

  return (
    <>
      {isOpenUser && (
        <StyleDrawerUser>
          <section className="DrawerUser">
            <div onClick={handleCloseUser} className="DrawerUser__handyClose">
              <div>Close</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width="20"
                viewBox="0 0 500 500"
                fill="currentColor"
                className="Drawer__icon">
                <path d="M443.6 387.1 312.4 255.4l131.5-130c5.4-5.4 5.4-14.2 0-19.6l-37.4-37.6c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L256 197.8 124.9 68.3c-2.6-2.6-6.1-4-9.8-4-3.7 0-7.2 1.5-9.8 4L68 105.9c-5.4 5.4-5.4 14.2 0 19.6l131.5 130L68.4 387.1c-2.6 2.6-4.1 6.1-4.1 9.8 0 3.7 1.4 7.2 4.1 9.8l37.4 37.6c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1L256 313.1l130.7 131.1c2.7 2.7 6.2 4.1 9.8 4.1 3.5 0 7.1-1.3 9.8-4.1l37.4-37.6c2.6-2.6 4.1-6.1 4.1-9.8-.1-3.6-1.6-7.1-4.2-9.7z" />
              </svg>
            </div>
            <div className="DrawerUser__info">
              <p>Email: {info?.user?.email}</p>
              <p>
                Created at: {`${timeOf.year}-${timeOf.month}-${timeOf.day}`}
              </p>
              <p>
                current time: {getCurrentTime()} from {getCurrentDate()}
              </p>
            </div>
            <SingOutButton />
          </section>
        </StyleDrawerUser>
      )}
    </>
  );
}
