import React from 'react';
import { LearnStyle } from './learnStyle';
import { useParams } from 'react-router-dom';
import Layout from '../layout/Layout';
import SideBar from '../../components/sideBar/SideBar';

import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';
import { createComponent } from '@lit/react';

type MyElementComponentProps = any;

export const ReturnB = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

const Learn = () => {
  const { id } = useParams();

  return (
    <Layout>
      <LearnStyle>
        <div>
          <h2 className="label"> Current program is: {id}</h2>

          <a href="/">
            <ReturnB
              labelTemplate="Watch video"
              colors="wh4u"
              type="button"
              className="button-return">
              Return to dashboard
            </ReturnB>
          </a>
        </div>
        <SideBar />
      </LearnStyle>
    </Layout>
  );
};

export default Learn;
