import { useState, useEffect } from 'react';
import { ProgramsStyle } from './programsStyle';
import useUser from '../../hooks/use-user';
import usePrograms from '../../hooks/dataPrograms';
import { useAppDispatch } from '../../redux/actions';
import { watching } from '../../redux/reducers';

export default function Programs() {
  const {
    userdb: { registeredOn },
  } = useUser();
  const [programs, setPrograms] = useState([]);
  const [display, setDisplay] = useState([]);
  const { api } = usePrograms();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const includePrograms = () => {
      setPrograms(registeredOn);
    };
    includePrograms();
  }, [registeredOn]);

  useEffect(() => {
    const filterPrograms = () => {
      const filtered = api?.programs.filter((item) => {
        return registeredOn?.includes(item.id);
      });
      setDisplay(filtered);
    };
    filterPrograms();
  }, [programs]);

  const assing = (id) => {
    dispatch(watching(id));
  };

  return (
    <ProgramsStyle>
      {registeredOn !== undefined ? (
        <div className="programsCard">
          {display.map((item) => (
            <div key={item.id} className="imageContainer">
              <a
                href={`dashboard/learn/${item.id}`}
                key={item.id}
                onClick={() => assing(item.id)}>
                <img
                  src={`${process.env.PUBLIC_URL}${item.image}-small.png`}
                  alt={item.id}
                  className="image-programs"
                  loading="lazy"
                />
              </a>
              <div className="image-programs__title">
                <p className="image-programs__title--text">{item.title}</p>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-sm">
          Check learnOnline section below to signup a course. When you have
          payment confirmation, Your programs will display here.
        </p>
      )}
    </ProgramsStyle>
  );
}
