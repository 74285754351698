import React, { useEffect, useState } from 'react';
import { ProgramsStyle } from './ProgramsStyle';
import { useParams, useNavigate, Link } from 'react-router-dom';
import LayoutProgram from '../layout/Layout';
import { ProgramList } from './programsDescription';

interface Program {
  title: string;
  content: string;
  image: string;
  episodes?: { id: number; title: string }[];
  price?: string;
}

const Descriptions = () => {
  const { id } = useParams();
  const [program, setProgram] = useState<Program | undefined>(undefined);
  const navigate = useNavigate();

  useEffect(() => {
    try {
      const fetchProgram = async () => {
        const res = await ProgramList.find((item) => item.title === id);
        setProgram(res);
      };
      fetchProgram();
    } catch (error) {
      console.log('We can not find the program');
    }
  }, [id]);

  return (
    <LayoutProgram>
      <ProgramsStyle>
        <section className="description">
          {program !== undefined ? (
            <article className="description-table">
              <div className="description-table__title">
                <h2>{id}</h2>

                <picture>
                  <source
                    srcSet={`${process.env.PUBLIC_URL}${program.image}-small.png`}
                    media="(min-width: 700px)"
                    className="description-table__image"
                  />
                  <source
                    srcSet={`${process.env.PUBLIC_URL}${program.image}-medium.png`}
                    className="description-table__image"
                    media="(min-width: 1024px)"
                  />
                  <img
                    src={`${process.env.PUBLIC_URL}${program.image}-small.png`}
                    className="description-table__image"
                    alt={program.title}
                    width="100%"
                    height="100%"
                  />
                </picture>
              </div>

              <div className="description-table__content">
                <p>{program.content}</p>
                <ol className="description-table__list">
                  <h3>Topics</h3>
                  {program.episodes?.map((item) => (
                    <li key={item.id}>{item.title}</li>
                  ))}
                </ol>

                <div className="description-table__price">
                  <h2>{program.price}</h2>
                </div>
              </div>
            </article>
          ) : (
            <p>We could not find this program</p>
          )}

          <Link to="/">
            <div className="button-check">Register and start!</div>
          </Link>
        </section>
      </ProgramsStyle>
    </LayoutProgram>
  );
};

export default Descriptions;
