import React, { ReactNode } from 'react';
import Footer from '../../components/footer/Footer';
import Header from '../../components/header/Header';

interface LayoutProps {
  children: ReactNode;
}

const LayoutProgram: React.FC<LayoutProps> = ({ children }) => {
  return (
    <div>
      <Header />

      <>{children}</>

      <Footer />
    </div>
  );
};

export default LayoutProgram;
