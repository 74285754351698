import styled from 'styled-components';

export const SingOutStyle = styled.form`
  .close {
    padding: 0.5rem;
    color: ${(props) => props?.theme.colors.background};
    background-color: ${(props) => props?.theme.colors.black};
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    border-radius: 0.375rem;
    cursor: pointer;
    border: none;

    &:hover {
      background: ${(props) => props?.theme.colors.red};
      color: ${(props) => props?.theme.colors['original-light']};
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .close {
      width: 120px;
    }
  }
`;
