import styled from 'styled-components';

export const ProgramsStyle = styled.div`
  .programsCard {
    font-family: ${(props) => props?.theme.fonts.primary};
    &__progress {
      height: 4px;
      display: flex;
    }
    progress {
      -webkit-appearance: none;
      margin-top: 1rem;
      &::-webkit-progress-value {
        background-color: ${(props) => props?.theme.colors.red};
      }
      &::-webkit-progress-bar {
        background-color: ${(props) => props?.theme.colors['o-soft']};
      }
    }
  }

  .imageContainer {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    position: relative;

    &::before {
      width: 50px;
      height: 50px;
      content: '4u';
      color: white;
      font-size: 20px;
      background-color: ${(props) => props?.theme.colors.red};
      position: absolute;
      font-family: ${(props) => props?.theme.fonts.secondary};
      display: flex;
      align-items: center;
      justify-content: center;
      top: -10px;
      left: -10px;
    }
  }

  .image-programs {
    width: 150px;
    margin-bottom: 1rem;

    &__title {
      width: 150px;
      font-family: ${(props) => props?.theme.fonts.secondary};
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .programsCard {
      width: 250px;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .imageContainer {
      flex-direction: column;
    }

    .programsCard {
      width: 300px;

      display: flex;
      flex-direction: row;
      gap: 1rem;

      &__progress {
        position: absolute;
        bottom: 0;
      }
    }

    .image-programs {
      width: 100%;
      height: 180px;
      display: flex;
      justify-content: space-between;

      object-fit: cover;

      &__progress {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: ${(props) => props?.theme.colors.red};
      }

      &__title {
        width: 240px;
        font-size: 1rem;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        color: ${(props) => props?.theme.colors.grey};

        &--text {
          height: 40px;
        }
      }
    }
  }
`;
