import InputResset from '../../components/passresset/InputResset';
import { Resetpass } from './styles';

function PasswordRessetPage() {
  return (
    <Resetpass>
      <h2>Reset my password</h2>
      <InputResset />

      <p>More than attempt for this page will be blocked</p>
    </Resetpass>
  );
}

export default PasswordRessetPage;
