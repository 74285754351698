import { useState, useEffect } from 'react';
import { LoginStyle } from './loginStyle';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from '../../lib/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import { login } from '../../redux/reducers';
import { useAppDispatch } from '../../redux/actions';

const Login = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const dispatch = useAppDispatch();

  const isInvalid = email === '' || password === '';

  useEffect(() => {
    const onAuth = onAuthStateChanged(auth, (currentUser) => {
      dispatch(login(currentUser));
    });
    return () => onAuth();
  }, []);

  const handleLogin = async (event) => {
    event.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/dashboard', { replace: true });
    } catch (error) {
      setEmail('');
      setPassword('');
      setError(error.message);
    }
  };

  return (
    <LoginStyle>
      <form onSubmit={handleLogin} method="POST" className="form">
        {error && <p className="error">{error}</p>}
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email"
          autoComplete="off"
          value={email}
          onChange={({ target }) => {
            setEmail(target.value.toLowerCase());
          }}
        />
        <input
          type="password"
          name=""
          id="password"
          placeholder="Password"
          value={password}
          onChange={({ target }) => {
            setPassword(target.value);
          }}
        />

        <button
          disabled={isInvalid}
          type="submit"
          className={`button-login ${isInvalid && 'invalid'}`}>
          {isInvalid ? 'Login' : 'ENTER'}
        </button>

        <a href="/resetpassword">
          <div
            style={{
              color: 'grey',
              textDecoration: 'underline',
              fontSize: '12px',
            }}>
            Forgot password
          </div>
        </a>
      </form>
    </LoginStyle>
  );
};

export default Login;
