const LogoImojePayments = () => {
  return (
    <>
      <style>
        {`

        ul{
          color: white;
          list-style: none;
        }
        
        .logo-payments {
          width: 60px;
          height: auto;
          background-color: white;
          margin-left: 10px;
          border-radius: 5px;
        }
        `}
      </style>
      <ul className="payments-request">
        <li>
          <img className="logo-payments" src="/imoje-1.png" alt="imoje" />
          <img className="logo-payments" src="/imoje-visa.jpeg" alt="imoje" />
          <img className="logo-payments" src="/blik.jpeg" alt="imoje" />
          <img className="logo-payments" src="/visa.jpeg" alt="imoje" />
        </li>
      </ul>
    </>
  );
};

export default LogoImojePayments;
