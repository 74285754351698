import { StyleHearts } from './StyleHearts';
import { useState, useEffect } from 'react';
import { db } from '../../lib/firebase';
import { doc, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';

type HeartType = { ida: string; docId: string; likedArticles: string[] };

export default function Hearts({ ida, docId, likedArticles }: HeartType) {
  const [full, setFull] = useState(false);
  const [convert, setConvert] = useState<string[]>([]);

  const updateLikes = async (ida: string) => {
    const docRefi = doc(db, 'users', docId);
    const update = async () => {
      try {
        !full
          ? await updateDoc(docRefi, {
              likedArticles: arrayUnion(ida),
            })
          : await updateDoc(docRefi, {
              likedArticles: arrayRemove(ida),
            });
      } catch (error: any) {
        console.error('error', error.message);
      }
    };
    update();
  };

  useEffect(() => {
    const tryingConversion = () => {
      const RESP = likedArticles.map((item) => item);
      setConvert(RESP);
    };

    if (likedArticles !== undefined) {
      tryingConversion();
    }
  }, [likedArticles]);

  useEffect(() => {
    if (convert.length > 0) {
      convert.includes(ida) ? setFull(true) : setFull(false);
    }
  }, [convert, ida]);

  return (
    <>
      {' '}
      <StyleHearts>
        <div
          className="heartbit"
          onClick={() => {
            setFull((prevState) => !prevState);
            updateLikes(ida);
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            style={{ width: '30px' }}
            fill={full ? '#e32c2b' : '#eee9e3'}
            viewBox="0 0 24 24"
            stroke={full ? '' : '#a39d99d9'}
            strokeWidth={1}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
            />
          </svg>
        </div>{' '}
      </StyleHearts>
    </>
  );
}
