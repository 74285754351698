import styled from 'styled-components';

export const TermsStyle = styled.form`
  .payments-request {
    list-style: none;
  }
  h1 {
    font-family: ${(props) => props?.theme.fonts.secondary};
    text-transform: uppercase;
    position: relative;
    display: inline-block;
  }

  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .divider {
    flex-grow: 1;
    height: 1px;
    background-color: #9f9f9f;
    margin-left: 20px;
  }

  section {
    padding: 20px;
  }

  @media screen and (min-width: 1030px) {
    section {
      width: 1000px;
      margin: auto;
    }
  }
`;
