import styled from 'styled-components';

export const LearnStyle = styled.div`
  position: relative;
  .button-return {
    cursor: pointer;
    position: fixed;
    right: 20px;
    bottom: 20px;
    z-index: 2;
  }

  .label {
    padding-left: 20px;
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .button-return {
      cursor: pointer;
      position: fixed;
      right: 20px;
      top: 20px;
      z-index: 2;
    }
  }
`;
