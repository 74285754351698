import styled from 'styled-components';

export const StyleLogoVsCode = styled.div`
  .Vscode-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .starts {
    list-style: none;
    display: flex;
    margin-left: 15px;
    color: ${(props) => props?.theme.colors.red};
  }

  .vscode {
    &__list {
      font-family: ${(props) => props?.theme.fonts.primary};
      list-style: none;
      &--li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        color: grey;

        &:hover {
          color: ${(props) => props?.theme.colors.red};
          text-decoration: underline;
        }
      }
    }
  }
  .vscode-img {
    width: 30px;
    margin-right: 10px;
  }
  .logovscode {
    padding: 5px;
    svg {
      width: 100%;
      height: auto;
    }
  }

  .title-span {
    background-color: ${(props) => props?.theme.colors.red};
    font-size: 14px;
    color: ${(props) => props?.theme.colors.white};
    padding: 0 5px;
  }

  @media screen and (min-width: 400px) {
    .title-span {
      background-color: transparent;
      color: ${(props) => props?.theme.colors.grey};
      padding: 0 5px;
    }
    .vscode {
      flex-grow: 3;
      padding-left: 30px;
      &__list {
        &--li {
          justify-content: flex-start;
          p {
            &:first-child {
              margin-left: 15px;
            }
          }
        }
      }
    }
  }

  @keyframes color-vscode {
    to {
      background-color: #000;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .Vscode-section {
      width: 700px;
      background-color: ${(props) => props?.theme.colors.black};
      margin: auto;
      padding: 15px;
      border-radius: 4px;
      animation: color-vscode 1s alternate infinite;
    }
    .title-span {
      color: ${(props) => props?.theme.colors.white};
      padding: 0 5px;
      font-size: 18px;
    }

    .vscode {
      &__list {
        font-family: ${(props) => props?.theme.fonts.primary};
        list-style: none;
        &--li {
          color: ${(props) => props?.theme.colors.white};

          &:hover {
            color: ${(props) => props?.theme.colors.red};
            text-decoration: underline;
          }
        }
      }
    }

    .logovscode {
      width: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
