import styled from 'styled-components';

export const Resetpass = styled.div`
  max-width: 400px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding-top: 100px;
`;
