import { collection, query, where, getDocs } from 'firebase/firestore';

import { db } from '../lib/firebase';

export const checkConversations = async (email: string, me: string) => {
  try {
    const userConversation = collection(db, 'conversations');
    const checkConverstion = query(
      userConversation,
      where('to', '==', email),
      where('from', '==', me)
    );
    const querySnapshot = await getDocs(checkConverstion);

    if (!querySnapshot.empty) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log('seems wrong checkingConverstions');
  }
};
