import React, { useState, useEffect, useRef, useCallback } from 'react';
import { StyleChats } from './StyleChats';
import { db } from '../../lib/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from 'firebase/firestore';
import { getCurrentTime, getCurrentDate } from '../../utility/dateToday';

interface ChatsProps {
  withUser: string;
  me: string;
}

type MsgTypes = {
  date: string;
  from: string;
  payload: string;
  time: string;
  viewed?: boolean;
};
interface UserMsg {
  messages: MsgTypes[];
}

export const Chats: React.FC<ChatsProps> = ({ withUser, me }) => {
  const [message, setMessage] = useState<UserMsg[]>([]); //probably sorted by time
  const [inputValue, setInputValue] = useState<string>('');
  const messagesContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      messagesContainerRef.current.scrollTop =
        messagesContainerRef.current.scrollHeight;

      setTimeout(() => {
        if (messagesContainerRef.current) {
          messagesContainerRef.current.scrollTop =
            messagesContainerRef.current.scrollHeight;
        }
      }, 300);
    }
  };

  const printChat = useCallback(() => {
    if (withUser === 'content@whitehat4u.com') {
      return query(
        collection(db, 'conversations'),
        where('to', '==', me), //invert to drawer
        where('from', '==', withUser)
      );
    } else {
      return query(
        collection(db, 'conversations'),
        where('to', '==', withUser), //invert to drawer
        where('from', '==', me)
      );
    }
  }, [me, withUser]);

  useEffect(() => {
    const loadMessages = async () => {
      try {
        const userMSG = printChat();

        //onSnapshot is to get update inmediatly instead get doc
        onSnapshot(userMSG, (querySnapShot) => {
          const usMsg: UserMsg[] = [];

          querySnapShot.forEach((doc) => {
            usMsg.push(doc.data() as UserMsg);
          });

          setMessage(usMsg);
          scrollToBottom();
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadMessages();
  }, [me, withUser]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleMessage = (event: React.FormEvent<HTMLButtonElement>) => {
    event.preventDefault();

    if (inputValue !== '') {
      const updateChat = async () => {
        try {
          const chatRef = printChat();

          const querySnapsShot = await getDocs(chatRef);

          if (!querySnapsShot.empty) {
            //if there is only one document of this kind mathc query where
            const docRef = querySnapsShot.docs[0].ref;

            await updateDoc(docRef, {
              messages: arrayUnion({
                from: me,
                date: getCurrentDate(),
                time: getCurrentTime(),
                payload: inputValue,
                viewed: false,
              }),
              pending: true,
            });
          } else {
            console.error('chat not found');
          }
        } catch (error) {
          console.error('update chat error:', error);
        }
      };

      updateChat();

      setInputValue('');
    }
  };

  const markMessagesAsViewed = async () => {
    try {
      const chatRef = printChat();
      const querySnapsShot = await getDocs(chatRef);

      if (!querySnapsShot.empty) {
        const docRef = querySnapsShot.docs[0].ref;
        const messagesToUpdate = message[0].messages.map((msg) =>
          msg.from !== me && !msg.viewed ? { ...msg, viewed: true } : msg
        );

        await updateDoc(docRef, {
          messages: messagesToUpdate,
        });
      }
    } catch (error) {
      console.error('Error marking messages as viewed:', error);
    }
  };

  return (
    <StyleChats>
      <div
        className="container-messages"
        onMouseEnter={markMessagesAsViewed}
        onTouchStart={markMessagesAsViewed}>
        <div className="container-messages__chatWith">
          Chat with: <b>Whitehat4u Team</b>
        </div>
        <section className="messages-wh4u" ref={messagesContainerRef}>
          {message.length > 0 &&
            message[0].messages.map((item, index) => (
              <div
                key={`${item.date}-time:${item.time}-${index}`}
                className={
                  item.from !== me
                    ? 'messages-wh4u__goes'
                    : 'messages-wh4u__income'
                }>
                <div> {item.payload}</div>
                <div className="messages-time">{item.time}</div>
              </div>
            ))}
        </section>

        <section>
          <form className="snaps-4u">
            <input
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              placeholder="wchat..."
              className="snaps-4u__input"
            />
            <button
              type="submit"
              onClick={handleMessage}
              className="snaps-4u__button">
              Send
            </button>
          </form>
        </section>
      </div>
    </StyleChats>
  );
};

export default Chats;
