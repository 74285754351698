import 'styled-components';
import { DefaultTheme } from 'styled-components';
type ObjectColor = {
  [key: string]: string;
};

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      [key: string]: string | ObjectColor;
      carnation: {
        '50': string;
        '100': string;
        '200': string;
        '300': string;
        '400': string;
        '500': string;
        '600': string;
        '700': string;
        '800': string;
        '900': string;
        '950': string;
      };
      bossanova: {
        '50': string;
        '100': string;
        '200': string;
        '300': string;
        '400': string;
        '500': string;
        '600': string;
        '700': string;
        '800': string;
        '900': string;
        '950': string;
      };
    };
    breakpoints: {
      mobileS: string;
      mobileM: string;
      mobileL: string;
      tablet: string;
      laptop: string;
      laptopL: string;
      desktop: string;
    };
    fonts: {
      primary: string;
      secondary: string;
    };
  }
}

export const theme: DefaultTheme = {
  colors: {
    primary: '#AC8E18',
    'primary-light': '#ff5454',
    carnation: {
      '50': '#fff1f1',
      '100': '#ffe1e1',
      '200': '#ffc7c7',
      '300': '#ffa0a0',
      '400': '#ff5454',
      '500': '#f83b3b',
      '600': '#e51d1d',
      '700': '#c11414',
      '800': '#a01414',
      '900': '#841818',
      '950': '#480707',
    },

    secondary: '#7B1C00',
    background: '#f7f7f5',
    matiz: {
      '50': '#f7f7f5',
      '100': '#e5e4dd',
      '200': '#dad8ce',
      '300': '#E5E4DD',
      '400': '#a8a28f',
      '500': '#978e78',
      '600': '#8a7f6c',
      '700': '#73695b',
      '800': '#5f564d',
      '900': '#4e4840',
      '950': '#292521',
    },
    bossanova: {
      '50': '#fbf7fc',
      '100': '#f6eff8',
      '200': '#ebdef0',
      '300': '#ddc4e3',
      '400': '#c8a0d2',
      '500': '#b07abb',
      '600': '#945b9e',
      '700': '#7a4982',
      '800': '#653d6b',
      '900': '#553659',
      '950': '#452349',
    },

    'bg-dark': '#aca273',
    red: '#e32c2b',
    black: '#333',
    autumn: '#cc686e',
    'green-100': '#c4f3c4',
    'green-200': '#4D7771',
    blue: '#C2FCF3',
    white: '#ffffff',
    original: '#eee9e3',
    'original-light': '#fdf9f2',
    'o-soft': '#a39d99d9',
    'yellow-200': ' #AC8E18',
    'purple-100': '#d3d4fa ',
    'purple-200': '#548894 ',
    grey: '#4d4d4d',
    amber: '#F8AC45',
    pink: ' #FFBBD7',
  },
  breakpoints: {
    mobileS: '250px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '700px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
  },
  fonts: {
    primary: "'Abel', sans-serif",
    secondary: 'whitehat',
  },
};
