import { FooterStyle } from './footerStyle';
import { Chevron } from '../../Icons/List';
import Typewriter from 'typewriter-effect';
import LogoImojePayments from '../logoPayments/LogoImoje';

const Footer = () => {
  return (
    <FooterStyle>
      <footer className="footer">
        <h2 className="labelText">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .typeString('Whitehat 4u')

                .start()
                .typeString(' <BR/>Contact');
            }}
          />
        </h2>

        <ul className="footer__directions">
          <li className="footer__directions--item">
            <span>
              <Chevron x="20" />
            </span>{' '}
            <a href="/Terms">Terms and Conditions </a>
          </li>

          <li className="footer__directions--item">
            <span>
              <Chevron x="20" />
            </span>
            Email: content@whitehat4u.com
          </li>
          <li className="footer__directions--item">
            <span>
              <Chevron x="20" />
            </span>
            <a href="https://discord.gg/dafktC8SZR">Discord App</a>
          </li>
          <li className="footer__directions--item">Version: 3.1.0</li>
          <li>Selected Payments</li>
        </ul>
        <LogoImojePayments />
      </footer>
    </FooterStyle>
  );
};

export default Footer;
