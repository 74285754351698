import { useEffect, useState } from 'react';

import { getUserByUserId } from '../services/emailExist';
import { useAppSelector } from '../redux/actions';

export default function useUser() {
  const [activeUser, setActiveUser] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const user = useAppSelector((state) => state.logUser.value);

  useEffect(() => {
    async function getUserObjByUserId() {
      try {
        //this is the main infoUser about
        const [response] = await getUserByUserId(user?.user?.uid);
        setActiveUser(response);
        setLoading(false);
        //generate redux for books
      } catch (error: any) {
        console.log(error.message);
      }
    }

    if (user?.user?.uid) {
      getUserObjByUserId();
    } else {
      setLoading(false);
    }
  }, [user]);

  return { userdb: activeUser, loading };
}
