/**
 * Utility to get the current time.
 *
 * This function returns the current time as a formatted string.
 * The time format is 'HH:MM:SS'.
 *
 * @returns {string} The current time.
 */
export const getCurrentTime = (): string => {
  const now = new Date();
  const hours = now.getHours().toString().padStart(2, '0');
  const minutes = now.getMinutes().toString().padStart(2, '0');
  const seconds = now.getSeconds().toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Utility to get the current date.
 *
 * This function returns the current date as a formatted string.
 * The date format is 'YYYY-MM-DD'.
 *
 * @returns {string} The current date.
 */
export const getCurrentDate = (): string => {
  const now = new Date();
  const year = now.getFullYear().toString();
  const month = (now.getMonth() + 1).toString().padStart(2, '0'); // getMonth() is zero-based
  const day = now.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};
