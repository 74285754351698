import { useEffect, useState } from 'react';

import Programs from '../pages/api/programs/eng.json';

type Program = {
  id: string;
  title: string;
  content: string;
  price: string;
  image: string;
  language: string;
  link?: string;
  external?: string[];
};

interface ProgramsAPI {
  programs: Program[];
}

export default function usePrograms(): { api: ProgramsAPI | undefined } {
  const [api, setApi] = useState<ProgramsAPI>();

  useEffect(() => {
    const getApi = () => {
      setApi(Programs);
    };

    getApi();
  }, []);

  return { api };
}
