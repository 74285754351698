import { collection, addDoc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { getCurrentTime, getCurrentDate } from './dateToday';

export const docConversation = (email: string, me: string) => {
  let makeDoc: boolean = false;

  try {
    const creation = async () => {
      await addDoc(collection(db, 'conversations'), {
        to: email,
        from: me,
        messages: [
          {
            from: me,
            time: getCurrentTime(),
            date: getCurrentDate(),
            payload: `Hi, welcome to our chat`,
            viewed: false,
          },
        ],
      });
    };
    creation();

    makeDoc = true;
  } catch (error) {
    console.log('something went wrong with the message');
  }

  return { makeDoc };
};
