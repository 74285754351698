import { TermsStyle } from './TermsStyle';

const Payments = () => {
  return (
    <TermsStyle>
      <section>
        <div className="container">
          <h1>Payments</h1>
          <div className="divider"></div>
        </div>
        <h3>To access private Programs</h3>
        <p>
          After registering on our website, click on the "Cart" icon
          corresponding to the selected course (preview selected).
        </p>

        <br />
        <p>
          The Customer may select the following payment methods for the ordered
          Goods: BLIK payment, payment card, electronic money transfer via an
          external imoje payment system operated by ING Bank Slaski S.A. with
          its registered office in Katowice, Poland.
        </p>

        <u>Program / course activation may take up to 24 hours.</u>
        <br />
        <br />
        <h2>Refund Policy</h2>
        <p>
          <strong>whitehat4u.com</strong> provides comprehensive descriptions of
          its programs and courses, as well not refund once purchased digital
          content.
          <h3 style={{ backgroundColor: '#aca273' }}>
            This policy is in strict compliance with the digital property law.
          </h3>
        </p>
        <br />
        <h2>Limited / Not refund/ Exclusion of Withdrawal Section</h2>
        <p>
          The service has been fully provided with the explicit consent of the
          user, who has been informed that they will lose the right to withdraw
          the refund once the payment is completed - Under article 7a of the
          Polish Consumers Rights Act. This applies defined as individuals
          making purchases related to their business but not of a professional
          nature for the buyer.
        </p>
        <h2>Sales</h2>
        <p>
          We accept Blik and payment cards transactions proccessed by payment
          links
        </p>
      </section>
    </TermsStyle>
  );
};

export default Payments;
