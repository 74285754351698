import React, { useState, useEffect, useRef } from 'react';
import { TheSlider } from './SliderStyle';

interface Slide {
  src: string;
  alt: string;
}

declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    fetchpriority?: 'high' | 'low' | 'auto';
  }
}

const SliderComponent: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const touchStartX = useRef<number>(0);
  const touchEndX = useRef<number>(0);
  const swipeThreshold = 50;

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slideItems.length);
  };

  const touchStart = (e: React.TouchEvent) => {
    touchStartX.current = e.touches[0].clientX;
  };

  const touchEnd = () => {
    const deltaX = touchStartX.current - touchEndX.current;

    if (Math.abs(deltaX) > swipeThreshold) {
      if (deltaX > 0) {
        nextSlide();
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(nextSlide, 3000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const slideItems: Slide[] = [
    { src: '/webComponents-medium.png', alt: 'Image 1' },
    { src: '/trailer-medium.jpg', alt: 'Image 3' },
  ];

  return (
    <TheSlider>
      <div
        className="slider"
        onClick={nextSlide}
        onTouchStart={(e) => touchStart(e)}
        onTouchEnd={() => touchEnd()}>
        <div
          className="slides"
          style={{ transform: `translateX(${-currentIndex * 100}%)` }}>
          {slideItems.map((slide, index) => (
            <div key={index} className="slide">
              <img src={slide.src} alt={slide.alt} fetchpriority="high" />
            </div>
          ))}
        </div>
      </div>
    </TheSlider>
  );
};

export default SliderComponent;
