import styled from 'styled-components';

export const ListUserStyle = styled.div`
  position: relative;
  .pending-list {
    margin: auto;
    background-color: ${(props) => props?.theme.colors.black};
    display: flex;
    padding: 10px;
    margin-top: 50px;
    &__ul {
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      justify-content: center;

      &--li {
        max-width: 200px;
        border-radius: 6px;
        padding: 5px 15px;
        background-color: ${(props) => props?.theme.colors.red};
      }
    }
  }

  .menu-con {
    display: flex;
    flex-direction: row;
  }

  .list-users {
    color: red;
    margin: auto;
    display: flex;
    gap: 15px;
    flex-wrap: wrap;
    padding: 20px;

    &__ul {
      color: ${(props) => props?.theme.colors.black};
      margin-top: 20px;
      font-size: 0.8rem;
      max-width: 300px;

      &--items {
        color: ${(props) => props?.theme.colors.red};
      }
    }
  }

  .card-list {
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: ${(props) => props?.theme.colors['original-light']};
    padding: 15px;
    border-radius: 10px;
    flex-direction: column;
    max-width: 300px;
    min-width: 200px;
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .pending-list {
      max-width: 600px;
    }
    .list-users {
      display: flex;
      justify-content: center;
    }
    .card-list {
      width: 300px;
      gap: 20px;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .list-users {
      width: 980px;
    }

    .card-list {
      display: flex;
      flex-direction: row;
      flex-grow: 2;
      align-items: center;
      background-color: ${(props) => props?.theme.colors['original-light']};
      padding: 15px;
      border-radius: 10px;
      gap: 10px;
    }

    ul {
      flex-grow: 2;
    }
  }
`;
