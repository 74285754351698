import React, { useState } from 'react';
import { ResetStyle } from './styles';
import { getAuth, sendPasswordResetEmail } from '../../lib/firebase';

function InputResset() {
  const [email, setEmail] = useState<string>('');
  const [msg, setMsg] = useState<boolean>(true);
  const [error, setError] = useState<string>('');
  const auth = getAuth();

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleReset = async () => {
    if (email !== '') {
      try {
        await sendPasswordResetEmail(auth, email);
        setMsg(false);
      } catch (error: unknown) {
        setMsg(true);
        setEmail('');
        setError(error as string);
      }
    }
  };

  return (
    <ResetStyle>
      {error !== '' && <p>Last Error: {error}</p>}
      {msg ? (
        <>
          <form className="reset-pass">
            <input
              type="email"
              name=""
              id=""
              className="reset-pass__input"
              placeholder="Email"
              onChange={handleInput}
            />
            <input
              type="button"
              value="Resset my Password"
              className="reset-pass__button"
              onClick={handleReset}
            />
          </form>
        </>
      ) : (
        <>
          email has been sent go to{' '}
          <a href="/" style={{ color: 'blue' }}>
            Login Page
          </a>{' '}
        </>
      )}
    </ResetStyle>
  );
}

export default InputResset;
