import { useState, useEffect } from 'react';
import Header from '../header/Header';
import { db } from '../../lib/firebase';
import { useAppSelector } from '../../redux/actions';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';

export default function Articles() {
  const [cover, setCover] = useState<File | null>(null);
  const [upMessage, setUpMessage] = useState('');
  const [ident, setIdent] = useState('');
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [publicArt, setPublic] = useState(false);

  const user = useAppSelector((state) => state.logUser.value);
  const storage = getStorage();
  const metadata = {
    contentType: 'image/jpeg',
  };

  const articleUpload = {
    id: ident,
    cover: cover?.name || '',
    title: title,
    content: content,
    author: 'whitehat4u',
    dateCreated: Date.now(),
    public: publicArt,
  };

  const tryUpload = async (selectedFile: File) => {
    console.log('Selected File:', selectedFile);

    if (!selectedFile || content === '') {
      console.log('Invalid file or empty content. Aborting upload.');
      return;
    }

    const imageRef = ref(storage, `articles/${selectedFile.name}`);

    await uploadBytes(imageRef, selectedFile, metadata);
    setUpMessage('Image uploaded to 🔥 🔥 Firebase Storage');

    await addDoc(collection(db, 'articles'), articleUpload);
  };

  const uploadImage = async (event: any) => {
    event.preventDefault();

    const selectedFile = cover;
    if (selectedFile && content !== '') {
      try {
        await tryUpload(selectedFile);
      } catch (error: any) {
        console.log('Error during upload:', error.message);
      }
    }
  };

  useEffect(() => {
    console.log('User changed:', user);
  }, [user]);

  console.log('public', articleUpload);

  return (
    <div>
      <Header />
      {user?.user?.uid === 'G8FZzYmtSRM4mjXYzVLGZLMwWtd2' ? (
        <main className="">
          <section>
            <h2>
              Write an<span>Article</span>
            </h2>
            <div className="">
              <div className="">
                <input
                  type="file"
                  onChange={(e) => {
                    const selectedFile = e.target.files?.[0];
                    if (selectedFile) {
                      setCover(selectedFile);
                    }
                  }}
                />

                <p>Status : {upMessage}</p>
                <p>
                  Rich editor:
                  <a
                    target="_blank"
                    href="https://smallseotools.com/online-text-editor/"
                    rel="noopener noreferrer">
                    https://smallseotools.com/online-text-editor/
                  </a>
                </p>
              </div>
              <div className="w-auto">
                <form onSubmit={uploadImage} method="POST">
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="Title of Article"
                    className=""
                    value={title}
                    onChange={({ target }) => {
                      setTitle(target.value);
                    }}
                  />
                  <input
                    type="text"
                    name=""
                    id=""
                    placeholder="04"
                    className=""
                    maxLength={2}
                    value={ident}
                    onChange={({ target }) => {
                      setIdent(target.value);
                    }}
                  />
                  <h2 className="">Is public article?</h2>
                  <select
                    onChange={(e) => setPublic(e.target.value === 'true')}>
                    <option value="">Select</option>
                    <option value="true">Yes</option>
                    <option value="false">No</option>
                  </select>

                  <textarea
                    name=""
                    id=""
                    cols={30}
                    rows={10}
                    maxLength={3000}
                    placeholder="Paste the rich text here"
                    className=""
                    value={content}
                    onChange={({ target }) => {
                      setContent(target.value);
                    }}></textarea>
                  <button type="submit">Upload article and image</button>
                </form>
              </div>
            </div>
          </section>
        </main>
      ) : (
        'Loading.. ggg.'
      )}
    </div>
  );
}
