import styled from 'styled-components';

export const StyleDinamic = styled.div`
  .article-content {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* Corrected property name */
    -webkit-box-orient: vertical; /* Corrected property name */
    overflow: hidden;
    font-family: ${(props) => props?.theme.fonts.primary};
    font-size: 16px; /* Corrected property name */
    section {
      padding: 0;
      flex-direction: column;
    }
  }

  .article-button {
    padding: 0.5rem;
    margin-top: 0.5rem;
    background: ${(props) => props?.theme.colors.black};
    color: ${(props) => props?.theme.colors.original};
    border: none;
    border-radius: 4px;
    &:hover {
      cursor: pointer;
    }
  }
  .article-news {
    margin: auto;
    padding: 0.75rem;
    margin-top: 0.25rem;
    margin-bottom: 40px;
    border-radius: 0.375rem;

    text-align: left;
    color: ${(props) => props?.theme.colors.black};

    &--uppercase {
      text-transform: uppercase;
      font-size: 1rem;
      margin-bottom: 0;
      color: ${(props) => props?.theme.colors.black};
    }

    &__id {
      padding: 0.5rem;
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;
      border-radius: 0.125rem;
      color: #ffffff;
      background-color: #000000;
    }

    &__images {
      object-fit: cover;
      width: 100%;
      max-height: 300px;
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  @media screen and (min-width: 500px) {
    .newsDinamic {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      padding: 30px;
    }

    .info-news {
      margin-top: 50px;
      padding: 5px;
      color: ${(props) => props?.theme.colors.black};
    }

    .article-news {
      display: flex;
      gap: 20px;
      border-radius: 2px;
      background-color: ${(props) => props?.theme.colors['original']};
      height: 250px;

      &__images {
        width: 250px;
        height: 150px;
      }
    }
  }

  @media screen and (min-width: 700px) {
    .newsDinamic {
      max-width: 900px;
      margin: auto;
    }
  }
`;
