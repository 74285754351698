import React, { useEffect } from 'react';
import Payments from '../payments/Payment';
import useUser from '../../hooks/use-user';
import { StyleCard } from './StyleCard';
import { useState } from 'react';
import { useAppSelector } from '../../redux/actions';

interface CardInformation {
  title: string | undefined;
  content: string | undefined;
  price: string | undefined;
  link: string | undefined;
  image: string | undefined;
  language: string;
  id: string | undefined;
  external?: string | string[];
}

const Cards: React.FC<CardInformation> = ({
  title,
  content,
  price,
  link,
  image,
  language = 'EN',
  id,
  external,
}) => {
  const programList = useAppSelector((state) => state.logUser.items);
  const userCode = useAppSelector((state) => state.logUser);
  const {
    userdb: { registeredOn },
  } = useUser();
  const [buttonstate, setButtonstate] = useState('');
  const [isIdInPrograms, setIsIdInPrograms] = useState(false);

  useEffect(() => {
    const check = programList.find((item: any) => item.id === id);
    if (check !== undefined) {
      setButtonstate('none');
    } else {
      setButtonstate('');
    }
  }, [programList, id, registeredOn]);

  useEffect(() => {
    if (registeredOn && id) {
      setIsIdInPrograms(registeredOn.includes(id));
    }
  }, [registeredOn, id]);

  return (
    <StyleCard>
      <>
        <div className="Card">
          <div className="Card__header">
            <h2>
              <b>{`${language}: `}</b>{' '}
              <span>
                {language === 'ES' ? 'Aprende' : 'Learn '} {title}
              </span>
            </h2>
          </div>

          <a
            href={
              external && external[0] === 'yes'
                ? `${external[1]}`
                : `/descriptions/${title}`
            }
            rel="noreferrer"
            target="_blank">
            <picture>
              <source
                srcSet={`${process.env.PUBLIC_URL}${image}-small.png`}
                media="(min-width: 700px)"
              />
              <source
                srcSet={`${process.env.PUBLIC_URL}${image}-medium.png`}
                media="(min-width: 1024px)"
              />
              <img
                src={`${process.env.PUBLIC_URL}${image}-small.png`}
                alt="Javascript"
                width="100%"
                height="100%"
                className="Image-Cards"
              />
            </picture>
          </a>

          <p className="">Description:</p>
          <p className="Card__content">{content}</p>

          {price !== '00' && registeredOn !== undefined ? (
            isIdInPrograms ? (
              'Item purchased'
            ) : (
              <div>
                <div
                  className={`Card__buttonPay`}
                  style={{ display: `${buttonstate}` }}>
                  <span className="Card__buttonPay--cart">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      style={{ width: '30px' }}
                      viewBox="0 0 20 20"
                      fill="currentColor">
                      <path d="M3 1a1 1 0 000 2h1.22l.305 1.222a.997.997 0 00.01.042l1.358 5.43-.893.892C3.74 11.846 4.632 14 6.414 14H15a1 1 0 000-2H6.414l1-1H14a1 1 0 00.894-.553l3-6A1 1 0 0017 3H6.28l-.31-1.243A1 1 0 005 1H3zM16 16.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM6.5 18a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" />
                    </svg>
                  </span>

                  <div className="Card__buttonPay--label">
                    <Payments id={id!} userCode={userCode.idx} price={price!} />
                  </div>
                </div>
              </div>
            )
          ) : (
            'Item ready'
          )}
        </div>
      </>
    </StyleCard>
  );
};

export default React.memo(Cards);
