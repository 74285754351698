import styled from 'styled-components';

export const FooterStyle = styled.footer`
  .labelText {
    position: inline-block;
    color: ${(props) => props?.theme.colors.background};
  }
  background-size: contain;
  background-repeat: repeat;
  padding-left: 20px;
  padding-right: 20px;
  background-color: ${(props) => props?.theme.colors.black};

  .footer {
    height: 400px;
    background-color: ${(props) => props?.theme.colors.black};

    &__directions {
      display: flex;
      flex-direction: column;
      padding: 10px;
      font-family: ${(props) => props?.theme.fonts.secondary};

      &--item {
        display: flex;
        color: ${(props) => props?.theme.colors.background};
        width: 100%;
        height: 30px;
        margin-top: 10px;
        list-style: none;
      }
    }
  }

  @media screen and (min-width: 1030px) {
    .footer {
      margin: auto;
      width: 1024px;

      &__directions {
        display: flex;
        margin: auto;

        &--item {
          width: fit-content;
        }
      }
    }
  }
`;
