import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import { ListUserStyle } from './listUserStyles';
import { useAppSelector } from '../../redux/actions';
import { createComponent } from '@lit/react';
import { Button } from '@juanpumpkinpie/gsx/dist/my-button.js';
import Chats from '../../components/Chats/Chat';
import { db } from '../../lib/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';
import { checkConversations } from '../../utility/checkConversations';
import { docConversation } from '../../utility/conversations';

//interfaces
interface UserList {
  [key: string]: string;
  registeredOn: any;
}
type MyElementComponentProps = any;
const MyElementComponent = createComponent<MyElementComponentProps>({
  tagName: 'my-button',
  elementClass: Button,
  react: React,
});

//component:
const ListUsers = () => {
  const {
    idx,
    value: {
      user: { email: me },
    },
  } = useAppSelector((state) => state.logUser);
  const [display, setDisplay] = useState<boolean>(true);
  const [pendings, setPendings] = useState<any>([]);
  const [list, setList] = useState<UserList[]>([]);
  const [openChat, setOpenChat] = useState<string | null>(null);
  const navigate = useNavigate();

  interface Conversation {
    from: string;
    to: string;
    messages: Object[];
    pending?: boolean;
  }

  useEffect(() => {}, [pendings]);

  useEffect(() => {
    const loadPending = async () => {
      try {
        const pending = query(
          collection(db, 'conversations'),
          where('to', '!=', false)
        );

        const querySnapsShot = await getDocs(pending);
        const pend: any = [];

        querySnapsShot.forEach((doc) => {
          pend.push(doc.data() as any);
          setPendings(pend);
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadPending();
  }, []);

  useEffect(() => {
    const loadUsers = async () => {
      try {
        const userList = query(
          collection(db, 'users'),
          where('email', '!=', false)
        );

        const querySnapsShot = await getDocs(userList);
        const usls: UserList[] = [];

        querySnapsShot.forEach((doc) => {
          usls.push(doc.data() as UserList);
          setList(usls);
        });
      } catch (error) {
        console.log(error);
      }
    };

    loadUsers();
  }, []);

  const handleResolve = async () => {
    try {
      const q = query(
        collection(db, 'conversations'),
        where('to', '==', openChat), //invert to drawer
        where('from', '==', 'content@whitehat4u.com')
      );

      const querySnapsShot = await getDocs(q);

      if (!querySnapsShot.empty) {
        const docRef = querySnapsShot.docs[0].ref;

        await updateDoc(docRef, {
          pending: false,
        });
      }
      navigate('/');
    } catch (error) {
      console.error('Error marking messages as viewed:', error);
    }
  };

  const handleOpenMessage = async (
    event: React.MouseEvent<HTMLAnchorElement>,
    email: string
  ) => {
    event.preventDefault();

    try {
      const doesChatExist = await checkConversations(email, me);

      if (!doesChatExist) {
        docConversation(email, me);
      } else {
        console.log('this conversation exist already');
      }

      setOpenChat(email);
      setDisplay(false);
    } catch (error) {
      console.error('Error opening message:', error);
    }
  };

  return (
    <Layout>
      <ListUserStyle>
        <div id="top">
          <ul key="somebutton" className="menu-con">
            <li>
              <a href="/" style={{ marginLeft: '10px' }}>
                <MyElementComponent
                  labelTemplate="go to dashboard"
                  colors="wh4u"
                  type="button"></MyElementComponent>
              </a>
            </li>
            {!display && (
              <li>
                <a
                  href="#top"
                  style={{ marginLeft: '10px', marginTop: '40px' }}>
                  <MyElementComponent
                    onClick={() => setDisplay(true)}
                    labelTemplate="ShowUsers"
                    colors="wh4u"
                    type="button"></MyElementComponent>
                </a>
              </li>
            )}
            {!display && (
              <>
                {pendings.length > 0 && (
                  <li style={{ marginLeft: '10px' }}>
                    {pendings.map((item: any) =>
                      item.to === openChat && item?.pending === true ? (
                        <MyElementComponent
                          onClick={handleResolve}
                          labelTemplate="Resolve"
                          colors="primary"
                          type="button"></MyElementComponent>
                      ) : null
                    )}
                  </li>
                )}
              </>
            )}
          </ul>

          {!display && openChat !== null && (
            <div className="height-chat">
              <Chats withUser={openChat} me={me} />
            </div>
          )}
        </div>
        {display && (
          <section className="pending-list">
            {pendings.length > 0 && (
              <ul className="pending-list__ul">
                {pendings.map((item: any, index: any) =>
                  item?.pending ? (
                    <div key={index}>
                      <a
                        href="#top"
                        onClick={(event) => handleOpenMessage(event, item.to)}>
                        <li className="pending-list__ul--li">{item.to}</li>
                      </a>
                    </div>
                  ) : null
                )}
              </ul>
            )}
          </section>
        )}

        {display && (
          <div className="list-users">
            {idx !== undefined && idx === process.env.REACT_APP_KEY_APP ? (
              <>
                {list.map((item, index) => (
                  <div className="card-list" key={`${index}-${item.name}-card`}>
                    <ul className="list-users__ul">
                      <li>{item.email}</li>
                      <li key={index}>
                        {item.name} {item.surname}
                      </li>
                      <li>
                        registered-On:
                        {item.registeredOn?.map(
                          (item: string, index: string) => (
                            <ul className="list-users__ul--items" key={index}>
                              <li>{item}</li>
                            </ul>
                          )
                        )}
                      </li>
                    </ul>
                    <a
                      href="#top"
                      onClick={(event) => handleOpenMessage(event, item.email)}>
                      <MyElementComponent
                        labelTemplate="DM"
                        colors="wh4u"
                        type="button"></MyElementComponent>
                    </a>
                  </div>
                ))}
              </>
            ) : (
              'Not access'
            )}
          </div>
        )}
      </ListUserStyle>
    </Layout>
  );
};

export default ListUsers;
