import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from '../redux/actions';

const ProtectedRoute = () => {
  const auth = useAppSelector(
    (state) => state.logUser.value?.user?.stsTokenManager?.accessToken
  );

  if (auth !== undefined) {
    return <Outlet />;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;
