import styled from 'styled-components';

export const StyleMessage = styled.div`
  @keyframes example {
    from {
      height: 0;
    }
    to {
      height: 100vh;
    }
  }

  .container-messages {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding-bottom: 20px;
  }

  .messages-wh4u {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 20px;
    height: 500px;
    overflow-y: scroll;

    &__income {
      display: inline-block;
      background-color: ${(props) => props?.theme.colors.red};
      padding: 10px;
      border-radius: 4px;
      max-width: fit-content;
    }
  }

  .snaps-4u {
    display: flex;
    gap: 10px;

    &__input {
      height: 34px;
      flex-grow: 2;
      padding: 4px 15px;
    }

    &__button {
      height: 34px;
      padding: 4px 15px;
      border: none;
      background-color: ${(props) => props?.theme.colors.red};
      color: white;
    }
  }

  .Drawer {
    padding: 15px;
    background: ${(props) => props?.theme.colors.black};
    width: 100%;
    color: white;
    position: fixed;
    z-index: 2;
    animation-name: example;
    animation-duration: 0.3s;
    animation-fill-mode: forwards;
    overflow-y: hidden;

    &__total {
      color: ${(props) => props?.theme.colors.white};
      padding-left: 15px;
      border-left: 2px solid ${(props) => props?.theme.colors.red};
    }

    &__handyClose {
      display: flex;
      justify-content: flex-end;
      &:hover {
        cursor: pointer;
      }
    }

    &__icon {
      color: red;
      margin-top: 3px;
      margin-left: 5px;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .Drawer {
      background: ${(props) => props?.theme.colors.black};
      width: 500px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      color: white;
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 2;
      animation-name: example;
      animation-duration: 0.3s;
      animation-fill-mode: forwards;
    }
  }
`;
