const LogoWhiteHat = () => (
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 110.54 23"
    width="288px"
    height="64px">
    <defs>
      <style>{'.cls-1{fill:#4d4d4d}'}</style>
    </defs>
    <path
      d="M0 0h3.28l-.15 1.66H1.69V13h1.59l-.15 1.7H0Z"
      transform="translate(0 -.04)"
      fill="#a39d99d9"
    />
    <path
      d="M88.35 10.45h-4.84l-.17-1.78 5-7.16h2.16v7h1.28l-.18 1.94h-1.08v2.61h-2.17Zm0-1.94v-4l-2.6 4ZM95.79 12.68A4.26 4.26 0 0 1 94.17 11a5.57 5.57 0 0 1-.55-2.54V1.51h2.43v6.73a3.23 3.23 0 0 0 .59 2 2.09 2.09 0 0 0 2.94.31 1.82 1.82 0 0 0 .31-.31 3.17 3.17 0 0 0 .6-2V1.51h2.42v6.91a5.32 5.32 0 0 1-.61 2.58 4.2 4.2 0 0 1-1.62 1.72 4.89 4.89 0 0 1-2.42.61 4.82 4.82 0 0 1-2.47-.65Z"
      transform="translate(0 -.04)"
      fill="#d10004"
    />

    <path
      d="M107.42 13h1.44V1.66h-1.59l.15-1.66h3.12v14.72h-3.27Z"
      transform="translate(0 -.04)"
      fill="#a39d99d9"
    />
    <path d="M109.04 23H63.8l-2.5-2.49H1.01v-1.32h108.03V23z" fill="#4d4d4d" />

    <path
      className="cls-1"
      d="M16.6 9.48a1.8 1.8 0 0 1-2 2h-4.49a1.8 1.8 0 0 1-2-2V1.36H6.46v8.12c0 2.43 1.21 3.65 3.65 3.65h4.46q3.64 0 3.65-3.65V1.36H16.6Z"
      transform="translate(0 -.04)"
    />
    <path
      className="cls-1"
      d="M10.18 10.77h2V3.33h-1.6V9.2a.35.35 0 0 1-.4-.39V1.37H8.62V9.2a1.38 1.38 0 0 0 1.56 1.57ZM16.12 9.2V1.37h-1.56v7.44a.34.34 0 0 1-.39.39V3.33H12.6v7.44h2a1.38 1.38 0 0 0 1.52-1.57Z"
      transform="translate(0 -.04)"
    />
    <path
      className="cls-1"
      d="M19.35 1.32h1.62v11.76h-1.62zM24.14 5.3h-1.19V1.34h-1.59v5.54h4.36V1.34h-1.58V5.3zM21.36 13.09h1.59V9.13h1.19v3.96h1.58V7.54h-4.36v5.55zM26.13 1.32h1.62v11.76h-1.62zM53.87 1.32h1.62v11.76h-1.62zM58.65 5.3h-1.18V1.34h-1.59v5.54h4.36V1.34h-1.59V5.3zM55.88 13.09h1.59V9.13h1.18v3.96h1.59V7.54h-4.36v5.55zM60.65 1.32h1.62v11.76h-1.62zM30.93 1.32h1.62v11.76h-1.62zM28.9 1.32h1.62v11.76H28.9zM72.6 1.32h9.48v1.62H72.6zM72.6 5.05h2.82v7.99h1.62V3.46H72.6v1.59zM77.6 13.04h1.61V5.05h2.83V3.46H77.6v9.58zM33.09 1.32h9.48v1.62h-9.48zM33.09 5.05h2.83v7.99h1.61V3.46h-4.44v1.59zM38.09 13.04h1.61V5.05h2.83V3.46h-4.44v9.58z"
    />
    <path
      className="cls-1"
      d="M44.6 9.48V5a1.79 1.79 0 0 1 2-2h4.9V1.36h-4.87C44.19 1.36 43 2.58 43 5v4.48c0 2.43 1.21 3.65 3.65 3.65h4.85v-1.62h-4.87a1.8 1.8 0 0 1-2.03-2.03Z"
      transform="translate(0 -.04)"
    />
    <path
      className="cls-1"
      d="M45.17 5.05V7h5.16V5.45h-3.57a.35.35 0 0 1 .39-.4h4.37V3.47h-4.76a1.4 1.4 0 0 0-1.59 1.58ZM46.76 9h3.57V7.43h-5.16v2A1.41 1.41 0 0 0 46.76 11h4.76V9.41h-4.37a.34.34 0 0 1-.39-.41ZM68.81 1.36a2.17 2.17 0 0 0-2 1.36L63 13.13h1.62l3.65-9.87a.66.66 0 0 1 .54-.26.54.54 0 0 1 .54.27l3.24 8.25h-4.8v1.62h7L70.83 2.72a1.94 1.94 0 0 0-2.02-1.36Z"
      transform="translate(0 -.04)"
    />
    <path
      className="cls-1"
      fill="#8492a6"
      d="M67.84 11h4l-2.75-7.19c0-.18-.13-.27-.26-.27s-.22.09-.26.27L65.15 13h1.58l1.12-2.83V11Zm1-3.42.72 1.84h-1.45Z"
      transform="translate(0 -.04)"
    />
  </svg>
);

export default LogoWhiteHat;
