import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import Header from '../header/Header';
import Footer from '../footer/Footer';
import { LayoutStyle } from './styles';

export interface LayoutProps {}

const LayoutComponent: FC<LayoutProps> = () => {
  return (
    <LayoutStyle>
      <Header />
      <div className="content">
        <Outlet />
      </div>
      <Footer />
    </LayoutStyle>
  );
};

export default LayoutComponent;
