import { db } from '../lib/firebase';
import {
  collection,
  query,
  where,
  getDocs,
  updateDoc,
} from 'firebase/firestore';

export async function doesEmailExist(email: string) {
  const userRef = collection(db, 'users');
  const checkUser = query(userRef, where('email', '==', email));
  const querySnapshot = await getDocs(checkUser);

  querySnapshot.forEach((doc) => {
    console.log(doc.id, ' => ', doc.data());
  });
}

export async function getUserByUserId(userId: any) {
  const dataUser: any = [];
  const userIdRef = collection(db, 'users');
  const checkId = query(userIdRef, where('id', '==', userId));
  const querySnapshot = await getDocs(checkId);

  querySnapshot.forEach((doc) => {
    //console.log("weed", doc.id, " => ", doc.data());
    dataUser.push({ ...doc.data(), docId: doc.id });
  });

  return dataUser;
}

// export async function updateArticlesLiked(userId){
//   const userArticleRef = doc(db, "users", userId);

//   await updateDoc(frankDocRef, {
//     "age": 93,
//     "favorites.color": "Red, Blue"
// });

// }
