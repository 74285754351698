import React from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from '../../redux/actions';
import { logout } from '../../redux/reducers';
import { SingOutStyle } from './signoutStyle';

export default function SingOutButton() {
  const navigate = useNavigate();
  const auth = getAuth();
  const dispatch = useAppDispatch();

  const logOut = async () => {
    try {
      localStorage.removeItem('persist:root');
      dispatch(logout());
      return await signOut(auth);
    } catch (error) {
      console.error('Type Error: user in logout Active');
    }
  };

  const handleHook = async (e: React.FormEvent) => {
    try {
      e.preventDefault();
      await logOut();
      setTimeout(() => {
        navigate('/');
      }, 200);
    } catch (error) {
      console.error('Error in singOut');
    }
  };

  return (
    <SingOutStyle>
      <button type="submit" className="close" onClick={handleHook}>
        Log out
      </button>
    </SingOutStyle>
  );
}
