import styled from 'styled-components';

export const LoginStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-family: ${(props) => props?.theme.fonts.secondary};
  .form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 6px;
    margin-top: 10px;

    input {
      padding: 1rem;
      padding-left: 1rem;
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.5rem;
      border-radius: 0.25rem;
      border: none;
      color: ${(props) => props?.theme.colors.black};
      font-family: ${(props) => props?.theme.fonts.secondary};

      &:active {
        outline: none;
        border: none;
      }

      &:focus {
        outline: 0;
      }
    }

    .button-login {
      margin-top: 10px;
      background-color: ${(props) => props?.theme.colors.grey};
      color: ${(props) => props?.theme.colors.white};
      cursor: pointer;
      padding: 0.6rem;
      border-radius: 0.25rem;
      border: none;
      &:hover {
        background: ${(props) => props?.theme.colors['o-soft']};
        color: white;
      }
    }
  }

  .invalid {
    opacity: 0.3;
  }
`;
