import styled from 'styled-components';

export const ResetStyle = styled.form`
  .reset-pass {
    max-width: 400px;
    gap: 10px;
    display: flex;
    flex-direction: column;
    margin: auto;

    &__input {
      padding: 10px 15px;
      border-radius: 4px;
      font-size: 14px;
      border: none;
    }

    &__button {
      color: white;
      background-color: red;
      border: none;
      padding: 10px 15px;
      border-radius: 4px;
      cursor: pointer;
    }
  }
`;
