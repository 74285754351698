import styled from 'styled-components';

export const TheSlider = styled.div`
  .slider {
    width: 100%;
    overflow: hidden;

    .slides {
      display: flex;
      transition: transform 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55);

      .slide {
        min-width: 100%;
      }

      img {
        width: 100%;
        height: auto;
      }
    }
  }
`;
