import styled from 'styled-components';

export const HeaderStylePrivate = styled.div`
  background-color: ${(props) => props?.theme.colors.original};
  padding-bottom: 40px;

  .top {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;

    &__cart {
      gap: 10px;
      height: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: baseline;

      margin-right: 20px;
      color: ${(props) => props?.theme.colors['o-soft']};

      &:hover {
        cursor: pointer;
      }
    }

    &__future {
      font-family: ${(props) => props?.theme.fonts.secondary};
      color: ${(props) => props?.theme.colors.black};
    }

    &__logoPlace {
      width: 200px;
      display: flex;
    }
  }

  .logOut {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .top {
      &__logoPlace {
        width: 400px;
        display: flex;
      }
    }
    .logOut {
      display: none;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    display: flex;
    justify-content: space-between;
    max-width: ${(props) => props?.theme.breakpoints.laptop};
    margin: auto;

    .top {
      width: ${(props) => props?.theme.breakpoints.laptop};
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__cart {
        width: 100px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;

        &:hover {
          cursor: pointer;
        }
      }

      &__logoPlace,
       &__future {
        display: flex;
        align-self: flex-start;
        font-size: 1rem;
      }
    }

    .logOut {
      display: flex;
      align-self: center;
    }
  }
`;
