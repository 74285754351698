import styled from 'styled-components';

export const StyleCookies = styled.div`
  .showCookies {
    width: 100%;
    height: 300px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: ${(props) => props?.theme.colors.red};
    position: fixed;
    bottom: 0;
    left: auto;
    z-index: 1;
    font-family: ${(props) => props?.theme.fonts.primary};
    font-size: 16px;
  }

  .hideCookies {
    display: none;
  }

  @media screen and (min-width: 980px) {
    .showCookies {
      font-size: 1.3rem;
    }

    span {
      position: absolute;
      right: 40px;
    }
  }
`;
