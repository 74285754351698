import React, { useEffect, useState, useMemo } from 'react';
import { useAppSelector } from '../../redux/actions';
import { StylePayments } from './StylePayments';

//extract the douId user to update into the request.

interface OrderData {
  [key: string]: any;
}

interface PaymentsProps {
  id: string;
  userCode: string;
  price: string;
}

const Payments: React.FC<PaymentsProps> = ({ id, userCode, price }) => {
  const [signatureReady, SetSignatureReady] = useState<string | null>(null);
  const user = useAppSelector((state) => state.logUser.value.user);

  const allParameters = [];
  const hashMethod = 'SHA-256';
  const serviceKey = 'WT-8SMfueB1tqWU9IdBvsnAdUIuqIIONkDor';

  const fields: OrderData = useMemo(() => {
    return {
      serviceId: '2e2bd78a-9080-4879-be4c-80a1b978e23d',
      merchantId: 'j04kz1il9r6kp6y3v9ry',
      amount: price,
      currency: 'PLN',
      orderId: userCode,
      orderDescription: id,
      title: id,
      customerFirstName: user.displayName,
      customerLastName: user.email,
      customerEmail: user.email,
      customerPhone: '',
      urlReturn: 'https://www.whitehat4u.com/dashboard',
      urlFailure:
        'https://www.whitehat4u.com/dashboard?status=last_transation_not_completed',
      urlSuccess: `https://www.whitehat4u.com/dashboard?status=transation_in_progress`,
      visibleMethod: ['blik', 'card'],
    };
  }, []);

  for (const item of Object.entries(fields)) {
    allParameters.push(item);
  }

  useEffect(() => {
    const deplacle = async () => {
      const sortedKeys = Object.keys(fields).sort();
      const allParams = sortedKeys
        .map((key) => `${key}=${fields[key]}`)
        .join('&');

      const encoder = new TextEncoder();
      const data = encoder.encode(allParams + serviceKey);
      try {
        const hashBuffer = await crypto.subtle.digest(hashMethod, data);
        const hashHex = Array.from(new Uint8Array(hashBuffer))
          .map((b) => b.toString(16).padStart(2, '0'))
          .join('');

        SetSignatureReady(`${hashHex};sha256`);
      } catch (error) {
        console.error('Error generating signature:', error);
      }
    };

    deplacle();
  }, [fields, hashMethod, serviceKey]);

  const generateFormInputs = () => {
    return Object.entries(fields).map(([key, value]) => (
      <input key={key} type="hidden" name={key} value={value} />
    ));
  };

  return (
    <StylePayments>
      {signatureReady && (
        <form
          method="POST"
          action="https://paywall.imoje.pl/payment"
          className="payment-button">
          {generateFormInputs()}
          <input type="hidden" value={signatureReady} name="signature" />
          <button type="submit" style={{ padding: '5px' }}>
            {price.slice(0, 2)} PLN
          </button>
        </form>
      )}
    </StylePayments>
  );
};

export default Payments;
