import { initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import {
  getAuth,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  sendEmailVerification,
} from 'firebase/auth';

import 'firebase/compat/auth';
import 'firebase/compat/firestore';

//import { seedDatabase } from "../data"

const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_DB_FIREBASE_KEY}`,
  authDomain: 'whitehat4u-d7536.firebaseapp.com',
  projectId: 'whitehat4u-d7536',
  storageBucket: 'whitehat4u-d7536.appspot.com',
  messagingSenderId: '712822262964',
  appId: '1:712822262964:web:f6876cd7d1b14e90979be1',
  measurementId: 'G-Z8RVFKHB57',
};

// Initialize Firebase
const Firebase = initializeApp(firebaseConfig);
const db = getFirestore(Firebase);
const storage = getStorage(Firebase);
const auth0 = getAuth(Firebase);

export {
  Firebase,
  db,
  getAuth,
  signInWithEmailAndPassword,
  storage,
  auth0,
  sendEmailVerification,
  sendPasswordResetEmail,
};

//seedDatabase(db);
