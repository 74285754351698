import styled from 'styled-components';

export const StyleChats = styled.div`
  //main container
  .container-messages {
    &__chatWith {
      padding: 20px;
    }
  }

  .hide-msg {
    display: none;
  }

  .messages-time {
    font-size: 0.8rem;
    display: flex;
    align-self: flex-end;
  }
  .messages-wh4u {
    display: flex;
    flex-direction: column;
    margin: auto;
    gap: 20px;
    max-height: 50%;
    overflow-y: scroll;
    max-width: 600px;
    padding: 15px;
    width: 100%;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */

    &__goes {
      display: inline-flex;
      background-color: ${(props) => props?.theme.colors.white};
      padding: 10px;
      border-radius: 4px;
      max-width: fit-content;
      gap: 10px;
      justify-content: space-between;
      flex-direction: column;
      color: ${(props) => props?.theme.colors.black};
      max-width: 250px;
    }

    &__income {
      display: inline-flex;
      align-self: flex-end;
      background-color: ${(props) => props?.theme.colors.red};
      opacity: 0.8;
      padding: 10px;
      border-radius: 4px;
      max-width: fit-content;
      gap: 10px;
      flex-direction: column;
      color: white;
      max-width: 250px;
    }
  }

  .snaps-4u {
    padding: 10px 20px;
    padding-bottom: 200px;
    display: flex;
    flex-direction: row;
    gap: 10px;

    &__input {
      height: 34px;
      flex-grow: 2;
      padding: 4px 15px;
      border: none;
      font-size: 16px;
      &:focus {
        outline: none;
      }
    }

    &__button {
      height: 34px;
      padding: 4px 15px;
      border: none;
      background-color: ${(props) => props?.theme.colors.red};
      color: white;
      border-radius: 4px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.tablet}) {
    .snaps-4u {
      max-width: 600px;
      margin: auto;
    }
  }

  @media screen and (min-width: ${(props) => props?.theme.breakpoints.laptop}) {
    .messages-wh4u {
      height: 600px;
    }
  }
`;
