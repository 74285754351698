import styled from 'styled-components';

export const ProgramsStyle = styled.section`
  .description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .description-table {
    &__list {
      margin-top: 2rem;
      line-height: 1.5rem;
      margin-left: 2rem;
    }

    &__price {
      background-color: ${(props) => props.theme.colors.red};
      color: ${(props) => props.theme.colors.original};
      padding: 5px;
      border-radius: 4px;
      margin-top: 20px;
      width: 100px;
      display: flex;
      justify-content: center;
    }
  }

  @media screen and (min-width: ${(props) => props.theme.breakpoints.laptop}) {
    .description-table {
      width: 1024px;
      margin: auto;
      display: flex;

      &__image {
        min-width: 500px;
        object-fit: cover;
      }

      &__content {
        margin-top: 20px;
        margin-left: 20px;
      }
    }
  }

  .button-check {
    margin-top: 100px;
    padding: 0.5rem 1rem;
    background-color: ${(props) => props.theme.colors.black};
    color: ${(props) => props.theme.colors.white};
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &:hover {
      background-color: ${(props) => props.theme.colors.red};
    }
  }
`;
