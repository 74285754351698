import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './styles/global.css';
import { ThemeProvider } from 'styled-components';
import { theme } from './styles/ThemeProvider';
import { store } from './redux/store';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor } from './redux/store';

import LandingPage from './pages/LandingPage';
import News from './pages/news/News';
import LayoutComponent from './components/publicLayout/Layout';
import ProtectedRoute from './services/ProtectedRoute';
import Dashboard from './pages/dashboard/Dashboard';
import Terms from './pages/Terms/Terms';
import Learn from './pages/learn/Learn';
import Descriptions from './pages/programs/Descriptions';
import Articles from './components/Articles/Articles';
import CookiesTerms from './pages/cookies/Cookies';
import ListUsers from './pages/listUsers/ListUsers';
import NotFound from './pages/notfound/NotFound';
import { Helmet } from 'react-helmet';
import PasswordRessetPage from './pages/passreset/PasswordReset';
const Application = () => {
  // const isAuthenticated =
  //   store.getState().logUser.value?.user?.stsTokenManager?.accessToken;

  // console.log(isAuthenticated);

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <BrowserRouter>
        <Provider store={store}>
          <PersistGate loading={<p>Loading</p>} persistor={persistor}>
            <Routes>
              <Route path="/" element={<LandingPage />} />
              <Route path="/Terms" element={<Terms />} />
              <Route path="/resetpassword" element={<PasswordRessetPage />} />
              <Route path="/Cookies" element={<CookiesTerms />} />
              <Route path="descriptions/:id" element={<Descriptions />} />

              <Route path="news" element={<LayoutComponent />}>
                <Route index element={<News />} />
                <Route path=":id" element={<News />} />
              </Route>

              <Route element={<ProtectedRoute />}>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="dashboard/learn/:id" element={<Learn />} />
                <Route path="articles" element={<Articles />} />
                <Route path="listusers" element={<ListUsers />} />
              </Route>

              <Route path="*" element={<NotFound />} />
            </Routes>
          </PersistGate>
        </Provider>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Application;
